import { stringify } from 'query-string';
import { fetchUtils, HttpError } from 'react-admin';
import authProvider from './authProvider';

const apiUrl = process.env.REACT_APP_API_URL;

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    const expireAt = localStorage.getItem('expireAt') || null;
    const status = localStorage.getItem('status') || null;

    console.log('now', Date.parse(new Date()) / 1000);
    console.log('expireAt', expireAt);
    console.log('status', status);

    if (status == 'login' && expireAt < Date.parse(new Date()) / 1000) {
        authProvider.logout().then((res) => {
            window.location.href = '/login';
            console.log(res);
        });
    }

    options.headers.set('Authorization', `JWT ${token}`);
    return fetchUtils.fetchJson(url, options);
};

export const httpClientFile = async (url, fileName, httpVerb, params) => fetch(params.data[fileName].src).
    then((r) => r.blob()).then((blob) => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(params.data));
        formData.append(fileName, blob);

        const token = localStorage.getItem('token');
        return fetch(url, {
            method: httpVerb,
            body: formData,
            headers: new Headers(
                {
                    'Authorization': `JWT ${token}`
                }
            )
        });
    }).then((response) => response.text().then((text) => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text
    }))).
    then(({ status, statusText, headers, body }) => {
        let json;
        try {
            json = JSON.parse(body);
        } catch (e) {
            // not json, no big deal
        }
        if (status < 200 || status >= 300) {
            return Promise.reject(
                new HttpError(
                    json && json.message || statusText,
                    status,
                    json
                )
            );
        }

        return Promise.resolve({ status, headers, body, json });
    });

export const httpClientMiniprogramFiles = async (url, httpVerb, params) => httpClientFile(url, 'coverImage', httpVerb, params);

const httpClientFileOld = (url, params) => fetch(params.data.file.src).
    then((r) => r.blob()).
    then((blob) => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(params.data));
        formData.append('file', blob);

        const token = localStorage.getItem('token');

        return fetch(url, {
            method: 'POST',
            body: formData,
            headers: new Headers(
                {
                    // "Content-Type": 'multipart/form-data',
                    'Authorization': `JWT ${token}`
                }
            )
        });
    }).
    then((response) => response.text().then((text) => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text
    }))).
    then(({ status, statusText, headers, body }) => {
        let json;
        try {
            json = JSON.parse(body);
        } catch (e) {
            // not json, no big deal
        }
        if (status < 200 || status >= 300) {
            return Promise.reject(
                new HttpError(
                    json && json.message || statusText,
                    status,
                    json
                )
            );
        }

        return Promise.resolve({ status, headers, body, json });
    });

export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter)
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;


        return httpClient(url).then(({ headers, json }) => (
            {
                data: json,
                total: parseInt(headers.get('Content-Range').split('/').pop(), 10)
            }
        ));
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json
        })),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids })
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id
            })
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('Content-Range').split('/').pop(), 10)
        }
        ));
    },

    getSchema: (resource) => {

        const url = `${apiUrl}/${resource}/schema`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json
            // total: parseInt(headers.get('Content-Range').split('/').pop(), 10),
        }
        ));
    },

    update: (resource, params) => {
        console.log('update');
        console.log(params);
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data)
        }).then(({ json }) => ({ data: json }));
    },

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids })
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data)
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
        if (params.data.file != null) {
            return httpClientFileOld(`${apiUrl}/${resource}`, params).
                then(({ json }) => ({
                    data: { ...params.data, id: json.id }
                }));

        }
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data)
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id }
        }));

    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE'
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids })
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data)
        }).then(({ json }) => ({ data: json }));
    },


    uploadFile: async (folder, fileInfo) => {

        // const data = await fetch(fileInfo.path);
        // // const data = await fetch(fileInfo.src);
        // // const file = await fetch(data.url);
        // const blob = await data.blob();

        const url = `${apiUrl}/files/${folder}`;

        // console.log('fileInfo');
        // console.log(fileInfo);

        // console.log('data');
        // console.log(data);

        // console.log('blob');
        // console.log(blob);

        // // console.log('file');
        // // console.log(file);
        const formData = new FormData();
        formData.append('files', fileInfo);

        const token = localStorage.getItem('token');

        return httpClient(url, {
            method: 'POST',
            headers: new Headers(
                {
                    // "Content-Type": 'multipart/form-data',
                    'Authorization': `JWT ${token}`
                }
            ),
            body: formData

        }).then(({ headers, json }) => ({
            data: json
            // total: parseInt(headers.get('Content-Range').split('/').pop(), 10),
        }
        ));
    }
};