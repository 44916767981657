import React from 'react';
import {
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';
import MyInputFile from '../fields/MyInputFile';

class WineCategoryEdit extends CustomTableEdit {

    customHandled(fields, fieldName, fieldList, parentFieldName) {

        if (fieldName === 'banner') {
            fieldList.push(<MyInputFile source={fieldName} label='Banner' folder='wine-category-banner' />);
            return true;
        } else if (fieldName === 'coverPicture') {
            fieldList.push(<MyInputFile source={fieldName} label='Cover image' folder='wine-category-cover' />);
            return true;
        } else if (fieldName === 'video') {
            fieldList.push(<MyInputFile source={fieldName} label='Video' folder='wine-category-video' />);
            return true;
        } else if (fieldName === 'images') {
            fieldList.push(<ArrayInput source='images'>
                <SimpleFormIterator>
                    <MyInputFile label='Image' folder='wine-category-image' />
                </SimpleFormIterator>
            </ArrayInput>);
            return true;
        } else if (fieldName === 'icon' && parentFieldName === 'flavours') {
            fieldList.push(<MyInputFile source={fieldName} label={'Icon'} folder={'wine-category-flavour'} />);
            return true;
        } else if (fieldName === 'image' && parentFieldName === 'grapeType') {
            fieldList.push(<MyInputFile source={fieldName} label={'Image'} folder={'wine-category-grape'} />);
            return true;
        } else if (fieldName === 'mainRegionEN' || fieldName === 'mainRegionCN') {
            let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
            fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);

            fieldList.push(<MyInputFile source={fieldName} label={fieldLabel} folder='wine-category-region' />);
            return true;
        }
        return false;
    }
}

export default WineCategoryEdit;
