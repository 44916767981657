import React from 'react';
import {
    SingleFieldList,
    Show,
    SimpleShowLayout,
    ArrayField,
    TextField,
    FunctionField,
    Datagrid,
    BooleanField,
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ImageFromAPIField from '../fields/ImageFromAPIField';
import VideoFromAPIField from '../fields/VideoFromAPIField';

class WineCategoryList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'descriptionEN' || fieldName === 'descriptionCN' || fieldName === 'images' || fieldName === 'flavours'
        || fieldName === 'grapeType' || fieldName === 'temperature' || fieldName === 'glassType' || fieldName === 'cellar'
        || fieldName === 'decent' || fieldName === 'howAreTheyMadeEN' || fieldName === 'howAreTheyMadeCN') {
            return true;
        }

        return false;
    }

    PostShow(props) {
        console.log(props.record);
        console.log('-------------------');
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>

                {/*<TextField label="Short Description (EN)" source={'shortDescriptionEN'} />*/}
                {/*<TextField label="Short Description (CN)" source={'shortDescriptionCN'} />*/}

                <TextField label="Description (EN)" source={'descriptionEN'} />
                <TextField label="Description (CN)" source={'descriptionCN'} />

                <TextField label="Cover image" />
                <ImageFromAPIField source="coverPicture" field="uri" folder="wine-category-cover" height="100"/>

                <TextField label="Banner" />
                <ImageFromAPIField source="banner" field="uri" folder="wine-category-banner" height="100"/>

                <TextField label="Video" />
                <VideoFromAPIField {...props} title= "Video" source={'video'} field="uri" folder="wine-category-video" height="300" />

                <ArrayField source="images">
                    <SingleFieldList>
                        <FunctionField render={(record) =>
                            <ImageFromAPIField {...props} record={{ ...record }} title="Image" field="uri" folder="wine-category-image" height="100" />
                        } />
                    </SingleFieldList>
                </ArrayField>

                <ArrayField source="flavours">
                    <Datagrid linkType={false}>
                        <ImageFromAPIField title="Icon" source={'icon'} field="uri" folder="wine-category-flavour" height="100" />
                        <TextField label="Name (EN)" source={'nameEN'}/>
                        <TextField label="Name (CN)" source={'nameCN'}/>
                    </Datagrid>
                </ArrayField>

                <ArrayField source="grapeType">
                    <Datagrid linkType={false}>
                        <ImageFromAPIField title="Image" source={'image'} field="uri" folder="wine-category-grape" height="100" />
                        <TextField label="Description (EN)" source={'descriptionEN'}/>
                        <TextField label="Description (CN)" source={'descriptionCN'}/>
                    </Datagrid>
                </ArrayField>

                <TextField label="Temperature" source={'temperature'} />
                <TextField label="Glass type" source={'glassType'} />
                <TextField label="Cellar" source={'cellar'} />
                <BooleanField label="Decent" source={'decent'} />

                <TextField label="Main region (EN)" />
                <ImageFromAPIField source="mainRegionEN" field="uri" folder="wine-category-region" height="100"/>
                <TextField label="Main region (CN)" />
                <ImageFromAPIField source="mainRegionCN" field="uri" folder="wine-category-region" height="100"/>

                <TextField label="How are they made (EN)" source={'howAreTheyMadeEN'} />
                <TextField label="How are they made (CN)" source={'howAreTheyMadeCN'} />
            </SimpleShowLayout>
        </Show>;
    }
}

export default WineCategoryList;
