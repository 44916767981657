import React from 'react';
import {cloneElement} from 'react';
import {
    useListContext,
    Button,
    sanitizeListRestProps,
} from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';


const SurveyExport = (props) => {

    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        showFilter,
    } = useListContext();

    const permissions = localStorage.getItem('permissions');

    return (<div className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}

        {(permissions === 'admin' || permissions === 'super-admin') && <Button
            onClick={() => {

                var headers = new Headers({Accept: 'application/json'});
                const token = localStorage.getItem('token');
                headers.set('Authorization', `JWT ${token}`);

                fetch(`${process.env.REACT_APP_API_URL}/surveys/${props.data.id}/export`, {
                    method: 'GET',
                    headers: headers
                })
                    .then(response => response.blob())
                    .then((blob) => {

                        /* LAUNCH THE DOWNLOAD */
                        const fakeLink = document.createElement('a');
                        fakeLink.style.display = 'none';
                        document.body.appendChild(fakeLink);
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            // Manage IE11+ & Edge
                            window.navigator.msSaveOrOpenBlob(blob, `Survey_export.xlsx`);
                        } else {
                            fakeLink.setAttribute('href', URL.createObjectURL(blob));
                            fakeLink.setAttribute('download', `Survey_export.xlsx`);
                            fakeLink.click();
                        }

                    })
                    .catch((e) => {
                        console.log('Error when exporting');
                    });
                console.log('ExportButton clicked')
            }}

            label="Export Survey Analytics"
        >
            <DownloadIcon/>
        </Button>}

    </div>)


};

export default SurveyExport;
