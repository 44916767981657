import React from 'react';
import {
    SingleFieldList,
    ChipField,
    ArrayField,
    FunctionField,
    Datagrid,
    TextField,
    ReferenceArrayField,
    ReferenceField
} from 'react-admin';
import CustomTableShow from './CustomTableShow';

class WineShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'tags') {
            fieldList.push(<ArrayField source={fieldName} label="List tag">
                <SingleFieldList>
                    <FunctionField render={(record) =>
                        <ChipField record={{ name: record }} source="name" />
                    } />
                </SingleFieldList>
            </ArrayField>);
            return true;
        } else if (fieldName === 'grapeType') {
            fieldList.push(<ArrayField source={fieldName}>
                <Datagrid linkType={false}>
                    <FunctionField label="Grape" render={record => {
                        if (record.grape === 'muscadet') {
                            return 'muscadelle';
                        } else {
                            return record.grape;
                        }
                    }} />
                    <TextField source={'percentage'}/>
                </Datagrid>
            </ArrayField>);
            return true;
        } else if (fieldName === 'recipe') {
            fieldList.push(<ReferenceField source={fieldName} reference={'wine-recipes'} link="show">
                <TextField source="nameEN" />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'sellingPoints') {
            fieldList.push(<ReferenceArrayField source={'sellingPoints'} reference={'selling-points'}>
                <SingleFieldList>
                    <ChipField source="nameEN" />
                </SingleFieldList>
            </ReferenceArrayField>);
            return true;
        } else if (fieldName === 'price') {
            fieldList.push(<TextField source={fieldName} label="Retail price" />);
            return true;
        } else if (fieldName === 'celebrity') {
            fieldList.push(<ReferenceField source={fieldName} reference={'celebrities'} label="KOL" link="show">
                <TextField source="nameEN" />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'sellingChannels') {
            fieldList.push(<ArrayField source={fieldName} label="Selling channel tag">
                <SingleFieldList>
                    <FunctionField render={(record) => {
                        let sellingChannels ={
                            "channel_ecommerce": "E-commerce",
                            "channel_catering": "Restaurant",
                            "channel_supermarket": "Supermarket",
                        }
                        return <ChipField record={{ name: sellingChannels[record] }} source="name" />
                    }} />
                </SingleFieldList>
            </ArrayField>);
            return true;
        } else if (fieldName === 'nameKol') {
            return true;
        }

        return false;
    }
}

export default WineShow;