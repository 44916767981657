import React from 'react';
import {
    ReferenceArrayField,
    SingleFieldList,
    TextField,
    ChipField,
    TopToolbar,
    Filter,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import CustomTableList from './CustomTableList';

import utils from '../utils/utils';
import ActivitiesExport from '../actions/ActivitiesExport';

class ActivityList extends CustomTableList {
    myFilter = (props) => (
        <Filter {...props}>
            <ReferenceInput label="Event" source="event" reference="events" alwaysOn allowEmpty perPage={500}>
                <AutocompleteInput optionText="nameEN" />
            </ReferenceInput>
        </Filter>
    );

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'typeEN') {
            fieldList.push(<TextField label="Type (EN)" source={fieldName}/>);
            return true;
        } else if (fieldName === 'typeCN') {
            fieldList.push(<TextField label="Type (CN)" source={fieldName}/>);
            return true;
        } else if (fieldName === 'themeEN') {
            fieldList.push(<TextField label="Theme (EN)" source={fieldName}/>);
            return true;
        } else if (fieldName === 'themeCN') {
            fieldList.push(<TextField label="Theme (CN)" source={fieldName}/>);
            return true;
        } else if (fieldName === 'spearkerEN') {
            fieldList.push(<TextField label="Speaker (EN)" source={fieldName}/>);
            return true;
        } else if (fieldName === 'spearkerCN') {
            fieldList.push(<TextField label="Speaker (CN)" source={fieldName}/>);
            return true;
        } else if (fieldName === 'descriptionEN') {
            fieldList.push(<TextField label="Description (EN)" source={fieldName}/>);
            return true;
        } else if (fieldName === 'descriptionCN') {
            fieldList.push(<TextField label="Description (CN)" source={fieldName}/>);
            return true;
        } else if (fieldName === 'jurisdictions') {
            const displayField = 'law';
            const ref = utils.getPlural(fields[fieldName].type[0].ref);

            fieldList.push(<ReferenceArrayField source={fieldName} reference={ref}>
                <SingleFieldList>
                    <ChipField source={displayField} />
                </SingleFieldList>
            </ReferenceArrayField>);
            return true;
        } else if (fieldName === 'stock') {
            fieldList.push(<TextField label={'Max number of attendees per activity'} source={fieldName}/>);
            return true;
        }
        return false;
    }

    customActions = (props) => {
        return (<TopToolbar>
            <ActivitiesExport {...props}/>
        </TopToolbar>)
    }
}

export default ActivityList;
