import React from 'react';
import {
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    TextInput, SelectInput, NumberInput, ReferenceInput, AutocompleteInput
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';
import MyInputFile from '../fields/MyInputFile';
import utils from "../utils/utils";

class WineCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'distributors') {
            fieldList.push(<ReferenceArrayInput label={'Distributors'} source={'distributors'} reference={'distributors'} allowEmpty>
                <AutocompleteArrayInput optionText={'companyNameEN'} />
            </ReferenceArrayInput>);
            return true;
        } else if (fieldName === 'picture') {
            fieldList.push(<MyInputFile source={fieldName} label="Picture" folder="wine-picture" />);
            return true;
        } else if (fieldName === 'tags') {
            fieldList.push(<ArrayInput source="tags" label="List tag">
                <SimpleFormIterator>
                    <SelectInput
                        label={'Tag'}
                        choices={[
                            { id: '2022_selection', name: '2022 selection 100' },
                            { id: '2022_selection_50', name: '2022 selection 50' },
                            { id: '2022_selection_newref', name: '2022 selection newref' },
                            { id: '2023_selection_newref', name: '2023 selection newref' },
                            { id: '2024_selection_100', name: '2024 selection 100' },
                        ]}
                    />
                </SimpleFormIterator>
            </ArrayInput>);
            return true;
        } else if (fieldName === 'sellingPoints') {
            fieldList.push(<ReferenceArrayInput label={'Selling points'} source={'sellingPoints'} reference={'selling-points'} allowEmpty>
                <AutocompleteArrayInput optionText={'nameEN'} />
            </ReferenceArrayInput>);
            return true;
        } else if (fieldName === 'sellingChannels') {
            fieldList.push(<ArrayInput source={'sellingChannels'} label={'Selling channel tag'}>
                <SimpleFormIterator>
                    <SelectInput label={'Selling channel'} choices={[
                        { id: 'channel_ecommerce', name: 'E-commerce' },
                        { id: 'channel_catering', name: 'Catering' },
                        { id: 'channel_supermarket', name: 'Supermarket' },
                    ]} />
                </SimpleFormIterator>
            </ArrayInput>);
            return true;
        } else if (fieldName === 'pictureKol') {
            fieldList.push(<MyInputFile source={fieldName} label="Picture KOL" folder="wine-picture-kol" />);
            return true;
        } else if (fieldName === 'pictureJury') {
            fieldList.push(<MyInputFile source={fieldName} label="Picture Jury" folder="wine-picture-jury" />);
            return true;
        } else if (fieldName === 'grapeType') {
            fieldList.push(<ArrayInput source={fieldName}>
                <SimpleFormIterator>
                    <SelectInput source={'grape'} label={'Grape type'} choices={[
                        { id: 'cabernet-sauvignon', name: 'Cabernet sauvignon' },
                        { id: 'sauvignon-blanc', name: 'Sauvignon blanc' },
                        { id: 'merlot', name: 'Merlot' },
                        { id: 'sauvignon-gris', name: 'Sauvignon gris' },
                        { id: 'cabernet-franc', name: 'Cabernet franc' },
                        { id: 'semillon', name: 'Semillon' },
                        { id: 'petit-verdot', name: 'Petit verdot' },
                        { id: 'muscadet', name: 'Muscadelle' },
                        { id: 'carmenere', name: 'Carmenere' },
                        { id: 'malbec', name: 'Malbec' },
                        { id: 'sauvignon', name: 'Sauvignon' },
                        { id: 'colombard', name: 'Colombard' },
                    ]} />
                    <NumberInput source={'percentage'} label={'Percentage'} />
                </SimpleFormIterator>
            </ArrayInput>);
            return true;
        } else if (fieldName === 'recipe') {
            fieldList.push(<ReferenceInput label='Wine Recipe' source={'recipe'} reference={'wine-recipes'} allowEmpty perPage={500}>
                <AutocompleteInput optionText={'nameEN'} />
            </ReferenceInput>);
            return true;
        } else if (fieldName === 'price') {
            fieldList.push(<TextInput label={'Retail price'} source={fieldName} key={'Retail price'}/>);
            return true;
        } else if (fieldName === 'celebrity') {
            const plural = utils.getPlural(fields[fieldName].ref)
            fieldList.push(<ReferenceInput label="KOL" source={fieldName} reference={plural} allowEmpty perPage={500}>
                <AutocompleteInput optionText={utils.displayFieldForPlural(plural)} />
            </ReferenceInput>);
            return true;
        } else if (fieldName === 'jury') {
            const plural = utils.getPlural(fields[fieldName].ref)
            fieldList.push(<ReferenceInput label="Jury" source={fieldName} reference={plural} allowEmpty perPage={500}>
                <AutocompleteInput optionText={utils.displayFieldForPlural(plural)} />
            </ReferenceInput>);
            return true;
        } else if (fieldName === 'nameKol') {
            return true;
        }
        return false;
    }
}

export default WineCreate;
