import React from 'react';
import {
    SingleFieldList,
    ChipField,
    ReferenceField,
    ReferenceArrayField,
    ReferenceManyField,
    TextField,
    ArrayField,
    Datagrid
} from 'react-admin';
import CustomTableShow from './CustomTableShow';

class DistributorShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'boothNum') {

            fieldList.push(<TextField source={fieldName}/>);
            fieldList.push(<ReferenceManyField label={'Wines'} reference={'wines'} target={'distributors'}>
                <SingleFieldList>
                    <ChipField source="nameEN" />
                </SingleFieldList>
            </ReferenceManyField>);

            return true;
        }

        return false;
    }
}

export default DistributorShow;