import React from 'react';
import {
    ReferenceField,
    TextField,
} from 'react-admin';
import CustomTableShow from './CustomTableShow';

class ActivityRegistrationShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        const role = localStorage.getItem('permissions');

        if (fieldName === 'activity') {
            fieldList.push(<ReferenceField source={fieldName} reference={'activities'}>
                <TextField source="themeEN" />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'user') {
            if (role === 'event-manager') {
                return true;
            }

            fieldList.push(<ReferenceField source={fieldName} reference={'wechat-users'} link="show">
                <TextField source="openid" />
            </ReferenceField>);
            return true;
        }
        return false;
    }
}

export default ActivityRegistrationShow;
