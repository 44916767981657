import React from 'react';
import {
    TopToolbar,
    EditButton
} from 'react-admin';
import CustomTableShow from './CustomTableShow';

import SurveyExport from '../actions/SurveyExport';

class SurveyShow extends CustomTableShow {
    customActions = (props) => {
        return (<TopToolbar>
            <EditButton resource={'surveys'} record={{ id: props?.data?.id }}/>
            <SurveyExport {...props}/>
        </TopToolbar>)
    }
}

export default SurveyShow;
