import React from 'react';
import {
    SingleFieldList,
    Show,
    SimpleShowLayout,
    ArrayField,
    TextField,
    FunctionField,
    ReferenceField,
    Datagrid,
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ImageFromAPIField from '../fields/ImageFromAPIField';
import VideoFromAPIField from '../fields/VideoFromAPIField';

class OccasionList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'descriptionEN' || fieldName === 'descriptionCN' || fieldName === 'images' || fieldName === 'foodPairings') {
            return true;
        } else if (fieldName === 'wineCategory') {
            fieldList.push(<ReferenceField source={fieldName} reference={'wine-categories'} link="show">
                <TextField source="nameEN" />
            </ReferenceField>);
            return true;
        }

        return false;
    }

    PostShow(props) {
        console.log(props.record);
        console.log('-------------------');
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>
                <TextField label="Description (EN)" source={'descriptionEN'} />
                <TextField label="Description (CN)" source={'descriptionCN'} />

                <TextField label="Cover image" />
                <ImageFromAPIField source="coverPicture" field="uri" folder="occasion-cover" height="100"/>

                <TextField label="Banner" />
                <ImageFromAPIField source="banner" field="uri" folder="occasion-banner" height="100"/>

                <TextField label="Video" />
                <VideoFromAPIField {...props} title= "Video" source={'video'} field="uri" folder="occasion-video" height="300" />

                <ArrayField source="images">
                    <SingleFieldList>
                        <FunctionField render={(record) =>
                            <ImageFromAPIField {...props} record={{ ...record }} title="Image" field="uri" folder="occasion-image" height="100" />
                        } />
                    </SingleFieldList>
                </ArrayField>

                <ArrayField source="foodPairings">
                    <Datagrid linkType={false}>
                        <ImageFromAPIField title="Image" source={'image'} field="uri" folder="occasion-food" height="100" />
                        <TextField label="Name (EN)" source={'nameEN'}/>
                        <TextField label="Name (CN)" source={'nameCN'}/>
                    </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
        </Show>;
    }
}

export default OccasionList;
