import React from 'react';
import {
    Filter,
    SelectInput,
    Show,
    SimpleShowLayout,
    ArrayField,
    Datagrid,
    ReferenceField,
    TextField,
    TextInput,
} from 'react-admin';
import CustomTableList from './CustomTableList';

class ActivityRegistrationList extends CustomTableList {
    myFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn />

            <SelectInput label="Type" source="type" allowEmpty
                choices={[
                    { id: 'shop', name: 'Shop' },
                    { id: 'restaurant', name: 'Restaurant' },
                    { id: 'hotel', name: 'Hotel' },
                    { id: 'wine_store', name: 'Wine store' },
                    { id: 'online_channel', name: 'Online channel' },
                ]} />
        </Filter>
    );

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'descriptionEN' || fieldName === 'descriptionCN' || fieldName === 'openingEN' || fieldName === 'openingCN'
        || fieldName === 'wines' || fieldName === 'mpAppId' || fieldName === 'mpPagePath') {
            return true;
        }

        return false;
    }

    PostShow(props) {

        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>
                <TextField label="MP Appid" source={'mpAppId'} />
                <TextField label="MP Page path" source={'mpPagePath'} />

                <TextField label="Description (EN)" source={'descriptionEN'} />
                <TextField label="Description (CN)" source={'descriptionCN'} />

                <TextField label="Opening hours (EN)" source={'openingEN'} />
                <TextField label="Opening hours (CN)" source={'openingCN'} />

                <ArrayField label={'Wines'} source={'wines'}>
                    <Datagrid>
                        <ReferenceField label={'Wine'} source={'wine'} reference={'wines'} allowEmpty>
                            <TextField source={'nameEN'} />
                        </ReferenceField>
                        <TextField label={'MP specific Page path'} source={'mpPagePath'} />
                    </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
        </Show>;
    }
}

export default ActivityRegistrationList;
