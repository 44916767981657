import React from 'react';
import {
    Show,
    SimpleShowLayout,
    AutocompleteInput,
    Filter,
    ReferenceField,
    ReferenceInput,
    TextField,
    TopToolbar,
    BooleanField,
    FunctionField,
} from 'react-admin';
import CustomTableList from './CustomTableList';
import LuckyDrawsExport from '../actions/LuckyDrawsExport';
import ApproveLuckyDrawButton from '../fields/ApproveLuckyDrawButton';
import LuckyDrawReviewButton from '../components/LuckyDrawReviewButton';

class LuckyDrawList extends CustomTableList {

    bulkActionButtons = true

    bulkAction = (props) => {
        return (<LuckyDrawReviewButton selectedIds={props.selectedIds} />)
    }

    myFilter = (props) => (
        <Filter {...props}>
            <ReferenceInput label="Event" source="event" reference="events" alwaysOn allowEmpty perPage={500} >
                <AutocompleteInput optionText="nameEN" />
            </ReferenceInput>
            <ReferenceInput label="Lucky draw" source="luckyDrawEvent" reference="lucky-draw-events" alwaysOn allowEmpty perPage={500} >
                <AutocompleteInput optionText="nameEN" />
            </ReferenceInput>
        </Filter>
    );

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'user') {
            const role = localStorage.getItem('permissions');
            if (role === 'admin' || role === 'super-admin') {
                fieldList.push(<ReferenceField source={fieldName} reference={'wechat-users'} link="show">
                    <TextField source="openid" />
                </ReferenceField>);
            }
            return true;
        } else if (fieldName === 'event') {
            fieldList.push(<ReferenceField source={fieldName} reference={'events'} link="show">
                <TextField source="nameEN" />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'luckyDrawEvent') {
            fieldList.push(<ReferenceField source={fieldName} reference={'lucky-draw-events'} link="show">
                <TextField source="nameEN" />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'winner') {
            fieldList.push(<BooleanField source={fieldName} />);
            fieldList.push(<FunctionField label="Validate" render={record => {
                if (record.luckyDrawEvent && record.winner !== true) {
                    return <ApproveLuckyDrawButton label={'Validate'} winner={true} record={record} />
                } else {
                    return <div></div>
                }
            }} />)
            return true;
        } else if (
            fieldName === 'city' ||
            fieldName === 'firstName' ||
            fieldName === 'lastName' ||
            fieldName === 'address' ||
            fieldName === 'email' ||
            fieldName === 'company'
        ) {
            return true;
        }
        return false;
    }

    customActions = (props) => {
        return (<TopToolbar>
            <LuckyDrawsExport {...props}/>
        </TopToolbar>)
    }

    PostShow(props) {
        console.log(props.record);
        console.log('-------------------');
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>
                <TextField label="City" source={'city'}/>
                <TextField label="First Name" source={'firstName'}/>
                <TextField label="Last Name" source={'lastName'}/>
                <TextField label="Address" source={'address'}/>
                <TextField label="Email" source={'email'}/>
                <TextField label="Company" source={'company'}/>
            </SimpleShowLayout>
        </Show>;
    }
}

export default LuckyDrawList;
