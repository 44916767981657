import React from 'react';

const ImageFromAPIField = ({ record, field, source, folder, height }) => {

    // console.log('folder');
    // console.log(folder);
    // if (folder === 'wine-picture') {
    //     console.log('record');
    //     console.log(record);

    //     console.log('field');
    //     console.log(field);

    //     console.log('source');
    //     console.log(source);
    // }

    let obj = record;
    if (source) {
        obj = record[source];
        if (source.split('[').length > 1) {
        // console.log('parseInt(source.split(\'[\')[1])');
        // console.log(parseInt(source.split('[')[1]));
        // console.log('source.split(\'.\')[1]');
        // console.log(source.split(']')[1]);
            obj = record[source.split('[')[0]][parseInt(source.split('[')[1])][source.split('.')[1]];
        }
    // for (var i = 0, source = source.split('.'), len = source.length; i < len; i++) {
    //     obj = obj[source[i]];
    // }
    }
    // if (folder === 'wine-picture') {
    //     console.log('obj');
    //     console.log(obj);
    // }

    if (obj && obj[field]) {
        const str = obj[field];
        if (folder === 'region-map-image') {
            console.log(`${process.env.REACT_APP_API_URL}/storage/${folder}/${str}`);
        }
        if (str) {
            // console.log('str');
            // console.log(str);
            return (
                <div>
                    <img src={`${process.env.REACT_APP_API_URL}/storage/${folder}/${str}`} alt={str} height={height} />
                </div>
            );
        }
    }
    return <a href={record[field]} >{record.title}</a>;

};

export default ImageFromAPIField;