import React from 'react';
import {
    DateField,
    ReferenceField,
    TextField,
    Filter,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';
import CustomTableList from './CustomTableList';
import utils from '../utils/utils';

class InvitationList extends CustomTableList {

    myFilter = (props) => (
        <Filter {...props}>
            <ReferenceInput label="Event" source="event" reference="events" alwaysOn allowEmpty >
                <AutocompleteInput optionText="nameEN" />
            </ReferenceInput>

        </Filter>
    );

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'event') {
            fieldList.push(<DateField source="createdAt" showTime />);

            const plural = `${fields[fieldName].ref}s`;
            fieldList.push(<ReferenceField source={fieldName} reference={plural} link="show">
                <TextField source={utils.displayFieldForPlural(plural)} />
            </ReferenceField>);
            return true;
        }
        return false;
    }

}

export default InvitationList;