import React from 'react';
import {
    FileInput,
    FileField,
    ImageField
} from 'react-admin';
import { useForm } from 'react-final-form';
import dataProvider from '../utils/dataProvider';
import ImageFromAPIField from '../fields/ImageFromAPIField';

const testuub = (file, folder) => new Promise((resolve, reject) => {

    dataProvider.uploadFile(folder, file).then((result) => {

        if (result.data) {
            console.log('PASSED HERE');
            console.log(result.data);
            resolve(result.data);
        }
        resolve(4);
    }).catch((err) => {
        console.error(err);
        reject(err);
    });
});

const MyInputFile = ({ source, label, folder, multiple, validate }) => {

    const form = useForm();

    const parse = (file) => {

        testuub(file, folder).then((res) => {
            console.log('upload done:');
            console.log(res);
            console.log(form);
            console.log('source');
            console.log(source);
            form.change(source, res);
        });
        return {};
    };

    return <FileInput multiple={multiple} source={source} label={label} parse={parse} validate={validate}>
        {/* <FileField source="src" title="title" /> */}
        {/* <ImageField source="src" title="title" /> */}
        <ImageFromAPIField
        // source="src"
            field="uri"
            multiple={multiple}
            folder={folder}
            height="150"/>

    </FileInput>;
};

export default MyInputFile;