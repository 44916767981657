import React from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    ReferenceInput,
    SimpleFormIterator,
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';
import MyInputFile from '../fields/MyInputFile';

class CelebrityEdit extends CustomTableEdit {

    customHandled(fields, fieldName, fieldList, parentFieldName) {

        if (fieldName === 'banner') {
            fieldList.push(<MyInputFile source={fieldName} label='Banner' folder='celebrity-banner' />);
            return true;
        } else if (fieldName === 'coverPicture') {
            fieldList.push(<MyInputFile source={fieldName} label='Cover image' folder='celebrity-cover' />);
            return true;
        } else if (fieldName === 'images') {
            fieldList.push(<ArrayInput source='images'>
                <SimpleFormIterator>
                    <MyInputFile label='Image' folder='celebrity-image' />
                </SimpleFormIterator>
            </ArrayInput>);
            return true;
        } else if (fieldName === 'wineCategory') {
            fieldList.push(<ReferenceInput label="Wine Category" source={'wineCategory'} reference={'wine-categories'} allowEmpty perPage={500}>
                <AutocompleteInput optionText={'nameEN'} />
            </ReferenceInput>);

            return true;
        } else if (fieldName === 'platformIcon' && parentFieldName === 'socialPlatforms') {
            fieldList.push(<MyInputFile source={fieldName} label={'Icon'} folder={'celebrity-icon'} />);
            return true;
        }
        return false;
    }
}

export default CelebrityEdit;
