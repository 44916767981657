import React from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    ReferenceInput,
    SimpleFormIterator,
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';
import MyInputFile from '../fields/MyInputFile';

class OccasionEdit extends CustomTableEdit {

    customHandled(fields, fieldName, fieldList, parentFieldName) {

        if (fieldName === 'banner') {
            fieldList.push(<MyInputFile source={fieldName} label='Banner' folder='occasion-banner' />);
            return true;
        } else if (fieldName === 'coverPicture') {
            fieldList.push(<MyInputFile source={fieldName} label='Cover image' folder='occasion-cover' />);
            return true;
        } else if (fieldName === 'video') {
            fieldList.push(<MyInputFile source={fieldName} label='Video' folder='occasion-video' />);
            return true;
        } else if (fieldName === 'images') {
            fieldList.push(<ArrayInput source='images'>
                <SimpleFormIterator>
                    <MyInputFile label='Image' folder='occasion-image' />
                </SimpleFormIterator>
            </ArrayInput>);
            return true;
        } else if (fieldName === 'wineCategory') {
            fieldList.push(<ReferenceInput label='Wine Category' source={'wineCategory'} reference={'wine-categories'} allowEmpty perPage={500}>
                <AutocompleteInput optionText={'nameEN'} />
            </ReferenceInput>);
            return true;
        } else if (fieldName === 'image' && parentFieldName === 'foodPairings') {
            fieldList.push(<MyInputFile source={fieldName} label={'Image'} folder={'occasion-food'} />);
            return true;
        } else if (fieldName === 'thumbnail') {
            fieldList.push(<MyInputFile source={fieldName} label='Thumbnail' folder='occasion-thumbnail' />);
            return true;
        }
        return false;
    }
}

export default OccasionEdit;
