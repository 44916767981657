import React from 'react';
import {
    ReferenceField,
    TextField
} from 'react-admin';
import CustomTableList from './CustomTableList';

class WineRatingList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'user') {
            fieldList.push(<ReferenceField source={fieldName} reference={'wechat-users'} link="show">
                <TextField source="openid" />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'wine') {
            fieldList.push(<ReferenceField source={fieldName} reference={'wines'} link="show">
                <TextField source="nameEN" />
            </ReferenceField>);
            return true;
        }
        return false;
    }
}

export default WineRatingList;