import React from 'react';
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';
import utils from "../utils/utils";

class SurveyCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'introEN' || fieldName === 'introCN' || fieldName === 'completionMessageEN' || fieldName === 'completionMessageCN') {
            let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
            fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);
            fieldList.push(<TextInput label={fieldLabel} source={fieldName} fullWidth multiline />);
            return true;
        } else if (fieldName === 'event') {
            const plural = utils.getPlural(fields[fieldName].ref)
            fieldList.push(<ReferenceInput label="Event" source={fieldName} reference={plural} allowEmpty perPage={500}>
                <AutocompleteInput optionText={utils.displayFieldForPlural(plural)} />
            </ReferenceInput>);

            return true;
        }
        return false;
    }
}

export default SurveyCreate;
