import React from 'react';
import {
    Filter,
    ReferenceInput,
    AutocompleteInput,
    Show,
    SimpleShowLayout,
    ReferenceField,
    BooleanField,
    TextField,
    TopToolbar,
    SelectInput,
    TextInput,
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ApproveRegistrationButton from '../fields/ApproveRegistrationButton';
import RegistrationStatusField from '../fields/RegistrationStatusField';
import EventRegistrationsExport from '../actions/EventRegistrationsExport';

class EventRegistationList extends CustomTableList {

    myFilter = (props) => (
        <Filter {...props}>
            <ReferenceInput label="Event" source="event" reference="events" alwaysOn allowEmpty perPage={500}>
                <AutocompleteInput optionText="nameEN" />
            </ReferenceInput>

            <TextInput label="Name" source="q" alwaysOn />

            <SelectInput label="Status" source="status" allowEmpty
                 choices={[
                     { id: 'pending', name: 'Pending' },
                     { id: 'participated', name: 'Participated' },
                     { id: 'cancelled', name: 'Cancelled' },
                 ]}/>
        </Filter>
    );

    customHandled(fields, fieldName, fieldList) {
        const role = localStorage.getItem('permissions');

        if (fieldName === 'companyType' || fieldName === 'jobTitle' || fieldName === 'phone' || fieldName === 'registerSuccessNotification'
        || fieldName === 'eventStartNotification' || fieldName === 'city' || fieldName === 'leadSource') {
            return true;
        } else if (fieldName === 'user') {
            if (role === 'event-manager') {
                return true;
            }

            fieldList.push(<ReferenceField source={fieldName} reference={'wechat-users'} link="show">
                <TextField source="openid" />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'event') {
            fieldList.push(<ReferenceField source={fieldName} reference={'events'}>
                <TextField source="nameEN" />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'status') {
            fieldList.push(<RegistrationStatusField label="Status" source="status" sortable={false} />);
            return true;
        } else if (fieldName === 'eventEndNotification') {
            // fieldList.push(<ApproveRegistrationButton label="Validate" status="validated"/>);
            // fieldList.push(<ApproveRegistrationButton label="Refuse" status="refused"/>);
            return true;
        }
        return false;
    }

    PostShow(props) {
        console.log(props.record);
        console.log('-------------------');
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>

                <TextField label="Company type" source={'companyType'}/>
                <TextField label="Job title" source={'jobTitle'}/>
                <TextField label="Phone" source={'phone'}/>

                <TextField label="City" source={'city'}/>
                <TextField label="Lead source" source={'leadSource'}/>

                <BooleanField label="Register success notification" source={'registerSuccessNotification'}/>
                <BooleanField label="Event start notification" source={'eventStartNotification'}/>
                <BooleanField label="Event end notification" source={'eventEndNotification'}/>

            </SimpleShowLayout>
        </Show>;
    }

    customActions = (props) => {
        return (<TopToolbar>
            <EventRegistrationsExport {...props}/>
        </TopToolbar>)
    }
}

export default EventRegistationList;
