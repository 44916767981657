import React from 'react';
import {
    ReferenceArrayInput,
    AutocompleteArrayInput,
    DateTimeInput,
    ReferenceInput,
    AutocompleteInput,
    NumberInput,
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';
import MyInputFile from '../fields/MyInputFile';
import utils from "../utils/utils";

class ActivityEdit extends CustomTableEdit {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'startDate') {
            fieldList.push(<DateTimeInput source={fieldName} label={'Start Date'} />);
            return true;
        } else if (fieldName === 'endDate') {
            fieldList.push(<DateTimeInput source={fieldName} label={'End Date'} />);
            return true;
        } else if (fieldName === 'event') {
            const plural = utils.getPlural(fields[fieldName].ref)
            fieldList.push(<ReferenceInput label="Event" source={fieldName} reference={plural} allowEmpty perPage={500}>
                <AutocompleteInput optionText={utils.displayFieldForPlural(plural)} />
            </ReferenceInput>);

            return true;
        } else if (fieldName === 'stock') {
            fieldList.push(<NumberInput label={'Max number of attendees'} source={fieldName} />);
            return true;
        }
        return false;
    }
}

export default ActivityEdit;
