import React from 'react';
import {
    SelectField
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';


const useStyles = makeStyles({
    participated: { color: 'blue' },
    ok: { color: 'green' },
    error: { color: 'red' },
    cancelled: { color: 'black' },
    warning: { color: 'orange' }
});

const RegistrationStatusField = (props) => {
    const classes = useStyles();
    return (
        <SelectField
            className={classnames({
                [classes.participated]: props.record[props.source] === 'participated',
                [classes.ok]: props.record[props.source] === 'validated',
                [classes.error]: props.record[props.source] === 'refused',
                [classes.cancelled]: props.record[props.source] === 'cancelled',
                [classes.warning]: props.record[props.source] !== 'validated' && props.record[props.source] !== 'refused' && props.record[props.source] !== 'cancelled' && props.record[props.source] !== 'participated'
            })}
            choices={[
                { id: 'participated', name: 'Participated' },
                { id: 'validated', name: 'Validated' },
                { id: 'refused', name: 'Refused' },
                { id: 'pending', name: 'Pending' },
                { id: 'cancelled', name: 'Cancelled' }
            ]}
            {...props}
        />
    );
};

export default RegistrationStatusField;
