import React from 'react';
import {
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    SimpleShowLayout,
    DateField,
    TextField,
    Show,
    TopToolbar,
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ImageFromAPIField from '../fields/ImageFromAPIField';
import EventsExport from '../actions/EventsExport';
import utils from '../utils/utils';

class EventList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        // if (fieldName === 'nameEN') {
        //     fieldList.push(<TextField label="Code" source={'code'}/>);
        //     fieldList.push(<TextField label="Name (EN)" source={fieldName}/>);
        //
        //     return true;
        // } else if (fieldName === 'nameCN') {
        //     fieldList.push(<TextField label="Name (CN)" source={fieldName}/>);
        //     return true;
        // } else if (fieldName === 'bannerEN') {
        //     fieldList.push(<ImageFromAPIField label="Banner (EN)" source={fieldName} field="uri" folder="event-banner" height="150"/>);
        //     return true;
        // } else if (fieldName === 'bannerCN') {
        //     fieldList.push(<ImageFromAPIField label="Banner (CN)" source={fieldName} field="uri" folder="event-banner" height="150"/>);
        //     return true;
        // } else if (fieldName === 'picture') {
        //     fieldList.push(<ImageFromAPIField source={fieldName} field="uri" folder="event-picture" height="150"/>);
        //     return true;
        // } else if (fieldName === 'exhibitors' || fieldName === 'descriptionEN' || fieldName === 'descriptionCN' || fieldName === 'code' || fieldName === 'startDate' || fieldName === 'endDate' || fieldName === 'venueEN' || fieldName === 'venueCN') {
        //     return true;
        // }


        // Hide banner
        if (fieldName === 'nameEN') {
            fieldList.push(<TextField label="Code" source={'code'}/>);
            fieldList.push(<TextField label="Name (EN)" source={fieldName}/>);

            return true;
        } else if (fieldName === 'nameCN') {
            fieldList.push(<TextField label="Name (CN)" source={fieldName}/>);
            return true;
        } else if (fieldName === 'picture') {
            fieldList.push(<ImageFromAPIField source={fieldName} field="uri" folder="event-picture" height="150"/>);
            return true;
        } else if (fieldName === 'activityLimitationPerUser') {
            fieldList.push(<TextField label="Max number of activities registration per user" source={fieldName}/>);
            return true;
        } else if (fieldName === 'bannerEN' || fieldName === 'bannerCN' || fieldName === 'exhibitors' || fieldName === 'producers'
        || fieldName === 'descriptionEN' || fieldName === 'descriptionCN' || fieldName === 'code' || fieldName === 'startDate'
        || fieldName === 'endDate' || fieldName === 'venueEN' || fieldName === 'venueCN' || fieldName === 'wineCategory'
        || fieldName === 'wines' || fieldName === 'wineTags' || fieldName === 'winesBannerEN' || fieldName === 'winesBannerCN'
        || fieldName === 'winesDescriptionEN' || fieldName === 'winesDescriptionCN') {
            return true;
        }
        return false;
    }

    PostShow(props) {
        // console.log(props.record);
        // console.log('-------------------');



        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>

                <TextField label="Description (EN)" source={'descriptionEN'}/>
                <TextField label="Description (CN)" source={'descriptionCN'}/>

                <TextField label="Venue (EN)" source={'venueEN'}/>
                <TextField label="Venue (CN)" source={'venueCN'}/>

                <DateField source={'startDate'} showTime={true} />
                <DateField source={'endDate'} showTime={true} />

                <TextField label="Banner (EN)" />
                <ImageFromAPIField label="Banner (EN)" source="bannerEN" field="uri" folder="event-banner" height="150"/>
                <TextField label="Banner (CN)" />
                <ImageFromAPIField label="Banner (CN)" source="bannerCN" field="uri" folder="event-banner" height="150"/>

                <ReferenceArrayField label={'Distributors'} source={'exhibitors'} reference={'distributors'}>
                    <SingleFieldList>
                        <ChipField source={utils.displayFieldForPlural('distributors')} />
                    </SingleFieldList>
                </ReferenceArrayField>

                <ReferenceArrayField source={'producers'} reference={'producers'}>
                    <SingleFieldList>
                        <ChipField source={utils.displayFieldForPlural('producers')} />
                    </SingleFieldList>
                </ReferenceArrayField>

                <ReferenceArrayField source={'wines'} reference={'wines'}>
                    <SingleFieldList>
                        <ChipField source={utils.displayFieldForPlural('wines')} />
                    </SingleFieldList>
                </ReferenceArrayField>
                {/* <ReferenceArrayField source={'distributors'} reference={'distributors'}>
                    <SingleFieldList>
                        <ChipField source="companyNameEN" />
                    </SingleFieldList>
                </ReferenceArrayField>

                <ArrayField source="grapeType">
                    <Datagrid linkType={false}>
                        <TextField source={'grape'}/>
                        <TextField source={'percentage'}/>

                    </Datagrid>
                </ArrayField> */}

            </SimpleShowLayout>
        </Show>;
    }

    customActions = (props) => {
        return (<TopToolbar>
            <EventsExport {...props}/>
        </TopToolbar>)
    }
}

export default EventList;
