import React from 'react';
import CustomTableEdit from './CustomTableEdit';
import MyInputFile from '../fields/MyInputFile';

class WineRecipeEdit extends CustomTableEdit {

    customHandled(fields, fieldName, fieldList, parentFieldName) {

        if (fieldName === 'banner') {
            fieldList.push(<MyInputFile source={fieldName} label='Banner' folder='wine-recipe-banner' />);
            return true;
        }
        return false;
    }
}

export default WineRecipeEdit;
