import React from 'react';
import {
    TextInput
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';
import MyInputFile from '../fields/MyInputFile';

class SellingPointEdit extends CustomTableEdit {

    customHandled(fields, fieldName, fieldList) {

        if (fieldName === 'logo') {
            fieldList.push(<MyInputFile source={fieldName} label="Logo" folder="selling-point-logo" />);
            return true;
        } else if (fieldName === 'descriptionEN' || fieldName === 'descriptionCN' || fieldName === 'openingEN' || fieldName === 'openingCN') {
            let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
            fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);
            fieldList.push(<TextInput label={fieldLabel} source={fieldName} fullWidth multiline />);
            return true;
        }
        return false;
    }
}

export default SellingPointEdit;
