/* eslint-disable complexity */
import * as React from 'react';
import { Admin, Resource } from 'react-admin';

import ActivityIcon from '@material-ui/icons/AddAPhoto';
import EventIcon from '@material-ui/icons/Camera';
import RegistrationIcon from '@material-ui/icons/Dock';
import PanoramaIcon from '@material-ui/icons/Panorama';
import MapIcon from '@material-ui/icons/Map';
import QuizzIcon from '@material-ui/icons/Book';
import PhotoGalleryIcon from '@material-ui/icons/PhotoAlbum';
import SurveyIcon from '@material-ui/icons/BookOutlined';
import SurveyAnswerIcon from '@material-ui/icons/BookOutlined';
import TasteboothResultIcon from '@material-ui/icons/QuestionAnswer';
import LuckyDrawIcon from '@material-ui/icons/CheckBox';
import DistributorIcon from '@material-ui/icons/Business';
// import ExhibitorIcon from '@material-ui/icons/Business';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import SellingPointIcon from '@material-ui/icons/Storefront';
import WineIcon from '@material-ui/icons/LocalDrink';
import WineCategoryIcon from '@material-ui/icons/Category';
import BadgeIcon from '@material-ui/icons/Star';
import RatingIcon from '@material-ui/icons/RateReview';
import WechatIcon from '@material-ui/icons/ChatBubble';
import UserIcon from '@material-ui/icons/Person';
import CelebrityIcon from '@material-ui/icons/People';
import OccasionIcon from '@material-ui/icons/Room';
import WineRecipeIcon from '@material-ui/icons/LocalBar';

import authProvider from './utils/authProvider';
import dataProvider from './utils/dataProvider';

import MyLoginPage from './pages/MyLoginPage';

import './App.css';

import CIVBTheme from './CIVBTheme';

import CustomTableCreate from './creates/CustomTableCreate';
import CustomTableEdit from './edits/CustomTableEdit';
import CustomTableList from './lists/CustomTableList';
import CustomTableShow from './shows/CustomTableShow';

import EventCreate from './creates/EventCreate';
import EventEdit from './edits/EventEdit';
import EventList from './lists/EventList';
import EventShow from './shows/EventShow';

import WineCreate from './creates/WineCreate';
import WineEdit from './edits/WineEdit';
import WineList from './lists/WineList';
import WineShow from './shows/WineShow';

import WineCategoryCreate from './creates/WineCategoryCreate';
import WineCategoryEdit from './edits/WineCategoryEdit';
import WineCategoryList from './lists/WineCategoryList';
import WineCategoryShow from './shows/WineCategoryShow';

import PanoramaList from './lists/PanoramaList';
import PanoramaEdit from './edits/PanoramaEdit';

// import EventMapEdit from './edits/EventMapEdit';
import EventMapList from './lists/EventMapList';

import ActivityCreate from './creates/ActivityCreate';
import ActivityEdit from './edits/ActivityEdit';
import ActivityList from './lists/ActivityList';
import ActivityShow from './shows/ActivityShow';

import ActivityRegistrationList from './lists/ActivityRegistrationList';
import ActivityRegistrationShow from './shows/ActivityRegistrationShow';
import ActivityRegistrationCreate from './creates/ActivityRegistrationCreate';
import ActivityRegistrationEdit from './edits/ActivityRegistrationEdit';

import WineRatingList from './lists/WineRatingList';

import EventRegistationList from './lists/EventRegistationList';
import EventRegistrationShow from './shows/EventRegistrationShow';

import InvitationList from './lists/InvitationList';

import LuckyDrawEventCreate from './creates/LuckyDrawEventCreate';
import LuckyDrawEventList from './lists/LuckyDrawEventList';
import LuckyDrawEventEdit from './edits/LuckyDrawEventEdit';
import LuckyDrawEventShow from './shows/LuckyDrawEventShow';

import LuckyDrawList from './lists/LuckyDrawList';

import BadgeCreate from './creates/BadgeCreate';
import BadgeEdit from './edits/BadgeEdit';
import BadgeList from './lists/BadgeList';

import WechatUserEdit from './edits/WechatUserEdit';

import RegionList from './lists/RegionList';

import QuizzCreate from './creates/QuizzCreate';
import QuizzEdit from './edits/QuizzEdit';
import QuizzList from './lists/QuizzList';

import PhotoGalleryCreate from './creates/PhotoGalleryCreate';
import PhotoGalleryEdit from './edits/PhotoGalleryEdit';
import PhotoGalleryList from './lists/PhotoGalleryList';

import SurveyList from './lists/SurveyList';
import SurveyEdit from './edits/SurveyEdit';
import SurveyCreate from './creates/SurveyCreate';
import SurveyShow from './shows/SurveyShow';

import SurveyAnswerList from './lists/SurveyAnswerList';
import SurveyAnswerShow from './shows/SurveyAnswerShow';

import createHistory from 'history/createBrowserHistory';

import DistributorCreate from './creates/DistributorCreate';
import DistributorList from './lists/DistributorList';
import DistributorEdit from './edits/DistributorEdit';
import DistributorShow from './shows/DistributorShow';

import ProducerCreate from './creates/ProducerCreate';
import ProducerList from './lists/ProducerList';
import ProducerEdit from './edits/ProducerEdit';
import ProducerShow from './shows/ProducerShow';

import RestaurantCreate from './creates/RestaurantCreate';
import RestaurantList from './lists/RestaurantList';
import RestaurantEdit from './edits/RestaurantEdit';
import RestaurantShow from './shows/RestaurantShow';

import TasteboothResultsList from './lists/TasteboothResultsList';

import SellingPointCreate from './creates/SellingPointCreate';
import SellingPointList from './lists/SellingPointList';
import SellingPointEdit from './edits/SellingPointEdit';

import CelebrityCreate from './creates/CelebrityCreate';
import CelebrityList from './lists/CelebrityList';
import CelebrityEdit from './edits/CelebrityEdit';
import CelebrityShow from './shows/CelebrityShow';

import OccasionCreate from './creates/OccasionCreate';
import OccasionList from './lists/OccasionList';
import OccasionEdit from './edits/OccasionEdit';
import OccasionShow from './shows/OccasionShow';

import WineRecipeCreate from './creates/WineRecipeCreate';
import WineRecipeList from './lists/WineRecipeList';
import WineRecipeEdit from './edits/WineRecipeEdit';

import JuryCreate from './creates/JuryCreate';
import JuryList from './lists/JuryList';
import JuryEdit from './edits/JuryEdit';
import JuryShow from './shows/JuryShow';

// import createHistory from 'history';

const history = createHistory();

class App extends React.Component {

    render() {
        return <Admin
            authProvider={authProvider}
            dataProvider={dataProvider}
            history={history}
            loginPage={MyLoginPage}
            theme={CIVBTheme}
            customRoutes={[]}>

            {(permissions) => [


                permissions === 'admin' || permissions === 'super-admin' || permissions === 'event-manager' || permissions === 'riviera' ? <Resource
                    name={'activities'}
                    create={permissions === 'admin' || permissions === 'super-admin' || permissions === 'event-manager' ? ActivityCreate : null}
                    list={ActivityList}
                    edit={permissions === 'admin' || permissions === 'super-admin' || permissions === 'event-manager' ? ActivityEdit : null}
                    show={ActivityShow}
                    icon={ActivityIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' || permissions === 'event-manager' ? <Resource
                    name={'activity-registrations'}
                    options={{label: 'Activity registrations'}}
                    create={permissions === 'admin' || permissions === 'super-admin' ? ActivityRegistrationCreate : null}
                    list={ActivityRegistrationList}
                    edit={ActivityRegistrationEdit}
                    show={ActivityRegistrationShow}
                    icon={ActivityIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'users'}
                    options={{label: 'Backoffice users'}}
                    create={CustomTableCreate}
                    list={CustomTableList}
                    edit={CustomTableEdit}
                    show={CustomTableShow}
                    icon={UserIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'badges'}
                    create={BadgeCreate}
                    list={BadgeList}
                    edit={BadgeEdit}
                    // show={CustomTableShow}
                    icon={BadgeIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' || permissions === 'event-manager' ? <Resource
                    name={'distributors'}
                    // name={'exhibitors'}
                    create={permissions === 'admin' || permissions === 'super-admin' ? DistributorCreate : null}
                    list={DistributorList}
                    edit={permissions === 'admin' || permissions === 'super-admin' ? DistributorEdit : null}
                    show={DistributorShow}
                    icon={DistributorIcon}
                    // icon={ExhibitorIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' || permissions === 'event-manager' || permissions === 'riviera' ? <Resource
                    name={'events'}
                    create={permissions === 'admin' || permissions === 'super-admin' || permissions === 'event-manager' ? EventCreate : null}
                    list={EventList}
                    edit={permissions === 'admin' || permissions === 'super-admin' || permissions === 'event-manager' ? EventEdit : null}
                    show={EventShow}
                    icon={EventIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'event-maps'}
                    options={{label: 'Event maps'}}
                    // create={CustomTableCreate}
                    list={EventMapList}
                    edit={CustomTableEdit}
                    show={CustomTableShow}
                    icon={MapIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' || permissions === 'event-manager' ? <Resource
                    name={'event-registrations'}
                    options={{label: 'Event registrations'}}
                    create={permissions === 'admin' || permissions === 'super-admin' ? CustomTableCreate : null}
                    list={EventRegistationList}
                    edit={CustomTableEdit}
                    show={EventRegistrationShow}
                    icon={RegistrationIcon}
                /> : null,

                permissions === 'riviera' || permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'invitations'}
                    create={CustomTableCreate}
                    list={InvitationList}
                    edit={CustomTableEdit}
                    show={CustomTableShow}
                    icon={RegistrationIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'juries'}
                    options={{label: 'Juries'}}
                    create={JuryCreate}
                    list={JuryList}
                    edit={JuryEdit}
                    show={JuryShow}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'celebrities'}
                    options={{label: 'KOL'}}
                    create={CelebrityCreate}
                    list={CelebrityList}
                    edit={CelebrityEdit}
                    show={CelebrityShow}
                    icon={CelebrityIcon}
                /> : null,

                permissions === 'lucky-draw-manager' || permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'lucky-draw-events'}
                    options={{label: 'Lucky draws'}}
                    create={LuckyDrawEventCreate}
                    list={LuckyDrawEventList}
                    edit={LuckyDrawEventEdit}
                    show={LuckyDrawEventShow}
                    icon={LuckyDrawIcon}
                /> : null,

                permissions === 'lucky-draw-manager' || permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'lucky-draws'}
                    options={{label: 'Lucky draw answers'}}
                    // create={CustomTableCreate}
                    list={LuckyDrawList}
                    // edit={CustomTableEdit}
                    // show={CustomTableShow}
                    icon={LuckyDrawIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'occasions'}
                    options={{label: 'Occasions'}}
                    create={OccasionCreate}
                    list={OccasionList}
                    edit={OccasionEdit}
                    show={OccasionShow}
                    icon={OccasionIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'panoramas'}
                    // create={CustomTableCreate}
                    list={PanoramaList}
                    edit={PanoramaEdit}
                    show={CustomTableShow}
                    icon={PanoramaIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' || permissions === 'event-manager' || permissions === 'riviera' ? <Resource
                    name={'photo-galleries'}
                    options={{label: 'Photo galleries'}}
                    create={PhotoGalleryCreate}
                    list={PhotoGalleryList}
                    edit={PhotoGalleryEdit}
                    // show={CustomTableShow}
                    icon={PhotoGalleryIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' || permissions === 'event-manager' ? <Resource
                    name={'producers'}
                    create={permissions === 'admin' || permissions === 'super-admin' ? ProducerCreate : null}
                    list={ProducerList}
                    edit={permissions === 'admin' || permissions === 'super-admin' ? ProducerEdit : null}
                    show={ProducerShow}
                    icon={DistributorIcon}
                    // icon={ExhibitorIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'quizzes'}
                    create={QuizzCreate}
                    list={QuizzList}
                    edit={QuizzEdit}
                    // show={CustomTableShow}
                    icon={QuizzIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'regions'}
                    // create={CustomTableCreate}
                    list={RegionList}
                    edit={CustomTableEdit}
                    // show={CustomTableShow}
                    icon={MapIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' || permissions === 'restaurant-manager' ? <Resource
                    name={'restaurants'}
                    create={RestaurantCreate}
                    list={RestaurantList}
                    edit={RestaurantEdit}
                    show={RestaurantShow}
                    icon={RestaurantIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'selling-points'}
                    options={{label: 'Selling points'}}
                    create={SellingPointCreate}
                    list={SellingPointList}
                    edit={SellingPointEdit}
                    show={CustomTableShow}
                    icon={SellingPointIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'surveys'}
                    create={SurveyCreate}
                    list={SurveyList}
                    edit={SurveyEdit}
                    show={SurveyShow}
                    icon={SurveyIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'survey-answers'}
                    options={{label: 'Survey answers'}}
                    // create={CustomTableCreate}
                    list={SurveyAnswerList}
                    // edit={CustomTableEdit}
                    show={SurveyAnswerShow}
                    icon={SurveyAnswerIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'tastebooth-results'}
                    options={{label: 'Tastebooth results'}}
                    // create={CustomTableCreate}
                    list={TasteboothResultsList}
                    // edit={CustomTableEdit}
                    // show={CustomTableShow}
                    icon={TasteboothResultIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'wechat-users'}
                    options={{label: 'Wechat users'}}
                    // create={CustomTableCreate}
                    list={CustomTableList}
                    edit={WechatUserEdit}
                    show={CustomTableShow}
                    icon={WechatIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' || permissions === 'restaurant-manager' ? <Resource
                    name={'wines'}
                    create={permissions === 'admin' || permissions === 'super-admin' ? WineCreate : null}
                    list={WineList}
                    edit={permissions === 'admin' || permissions === 'super-admin' ? WineEdit : null}
                    show={WineShow}
                    icon={WineIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' || permissions === 'event-manager' || permissions === 'riviera' ? <Resource
                    name={'wine-categories'}
                    options={{label: 'Wine categories'}}
                    create={permissions === 'admin' || permissions === 'super-admin' ? WineCategoryCreate : null}
                    list={WineCategoryList}
                    edit={permissions === 'admin' || permissions === 'super-admin' ? WineCategoryEdit : null}
                    show={WineCategoryShow}
                    icon={WineCategoryIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'wine-ratings'}
                    options={{label: 'Wine ratings'}}
                    create={CustomTableCreate}
                    list={WineRatingList}
                    edit={CustomTableEdit}
                    show={CustomTableShow}
                    icon={RatingIcon}
                /> : null,

                permissions === 'admin' || permissions === 'super-admin' ? <Resource
                    name={'wine-recipes'}
                    options={{label: 'Wine recipes'}}
                    create={WineRecipeCreate}
                    list={WineRecipeList}
                    edit={WineRecipeEdit}
                    show={CustomTableShow}
                    icon={WineRecipeIcon}
                /> : null,
            ]}

        </Admin>;
    }
}

require('dotenv').config();


export default App;