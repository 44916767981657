import React from 'react';
import {
    SingleFieldList,
    Show,
    SimpleShowLayout,
    ArrayField,
    TextField,
    FunctionField,
    ReferenceField,
    Datagrid,
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ImageFromAPIField from '../fields/ImageFromAPIField';

class JuryList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'biographyEN' || fieldName === 'biographyCN' || fieldName === 'images'
        || fieldName === 'socialPlatforms' || fieldName === 'wineCategories'
        || fieldName === 'titleEN' || fieldName === 'titleCN') {
            return true;
        }

        return false;
    }

    PostShow(props) {
        console.log(props.record);
        console.log('-------------------');
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>

                <TextField label="Title (EN)" source={'titleEN'} />
                <TextField label="Title (CN)" source={'titleCN'} />

                <TextField label="Biography (EN)" source={'biographyEN'} />
                <TextField label="Biography (CN)" source={'biographyCN'} />

                <TextField label="Cover image" />
                <ImageFromAPIField source="coverPicture" field="uri" folder="jury-cover" height="100"/>

                <TextField label="Banner" />
                <ImageFromAPIField source="banner" field="uri" folder="jury-banner" height="100"/>

                <ArrayField source="images">
                    <SingleFieldList>
                        <FunctionField render={(record) =>
                            <ImageFromAPIField {...props} record={{ ...record }} title="Image" field="uri" folder="jury-image" height="100" />
                        } />
                    </SingleFieldList>
                </ArrayField>

                <ArrayField source="wineCategories">
                    <Datagrid linkType={false}>
                        <ReferenceField source={'wineCategory'} reference={'wine-categories'} link="show">
                            <TextField source={'nameEN'} />
                        </ReferenceField>

                        <TextField label="Tasting note (EN)" source={'tastingNoteEN'}/>
                        <TextField label="Tasting note (CN)" source={'tastingNoteCN'}/>
                    </Datagrid>
                </ArrayField>

                <ArrayField source="socialPlatforms">
                    <Datagrid linkType={false}>
                        <ImageFromAPIField title="Icon" source={'platformIcon'} field="uri" folder="jury-icon" height="100" />
                        <TextField label="Platform (EN)" source={'platformNameEN'}/>
                        <TextField label="Platform (CN)" source={'platformNameCN'}/>
                        <TextField label="Name" source={'name'}/>
                    </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
        </Show>;
    }
}

export default JuryList;
