import React from 'react';
import CustomTableEdit from './CustomTableEdit';
import MyInputFile from '../fields/MyInputFile';


class PanoramaEdit extends CustomTableEdit {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'backImage' || fieldName === 'frontImage' || fieldName === 'leftImage' || fieldName === 'rightImage' ||
        fieldName === 'upImage' || fieldName === 'downImage') {
            fieldList.push(<MyInputFile source={fieldName} folder="panorama-panel" />);
            return true;
        }
        return false;
    }
}

export default PanoramaEdit;