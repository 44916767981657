import React from 'react';
import {
    SingleFieldList,
    ArrayField,
    FunctionField,
    ReferenceField,
    TextField,
    Datagrid,
} from 'react-admin';
import CustomTableShow from './CustomTableShow';
import ImageFromAPIField from "../fields/ImageFromAPIField";

class JuryShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'images') {
            fieldList.push(<ArrayField source="images">
                <SingleFieldList>
                    <FunctionField render={(record) =>
                        <ImageFromAPIField record={{ ...record }} title="Image" field="uri" folder="jury-image" height="100" />
                    } />
                </SingleFieldList>
            </ArrayField>);
            return true;
        } else if (fieldName === 'wineCategories') {
            fieldList.push(<ArrayField source="wineCategories">
                <Datagrid linkType={false}>
                    <ReferenceField source={'wineCategory'} reference={'wine-categories'} link="show">
                        <TextField source={'nameEN'} />
                    </ReferenceField>

                    <TextField label="Tasting note (EN)" source={'tastingNoteEN'}/>
                    <TextField label="Tasting note (CN)" source={'tastingNoteCN'}/>
                </Datagrid>
            </ArrayField>);
            return true;
        } else if (fieldName === 'socialPlatforms') {
            fieldList.push(<ArrayField source="socialPlatforms">
                <Datagrid linkType={false}>
                    <ImageFromAPIField title="Icon" source={'platformIcon'} field="uri" folder="jury-icon" height="100" />
                    <TextField label="Platform (EN)" source={'platformNameEN'}/>
                    <TextField label="Platform (CN)" source={'platformNameCN'}/>
                    <TextField label="Name" source={'name'}/>
                </Datagrid>
            </ArrayField>);
            return true;
        }
        return false;
    }
}

export default JuryShow;
