import React from 'react';
import {
    ArrayInput,
    SimpleFormIterator,
    ReferenceInput,
    AutocompleteInput,
    NumberInput,
    TextInput
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';
import MyInputFile from '../fields/MyInputFile';
import utils from '../utils/utils';

class RestaurantCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'wines') {
            // fieldList.push(<ReferenceArrayInput label={'Wines'} source={'wines'} reference={'wines'} allowEmpty>
            //     <AutocompleteArrayInput optionText={'nameEN'} />
            // </ReferenceArrayInput>);

            fieldList.push(<ArrayInput label={'Wines'} source={'wines'}>
                <SimpleFormIterator>
                    <ReferenceInput label={'Wine'} source={'wine'} reference={'wines'} allowEmpty>
                        <AutocompleteInput optionText={utils.displayFieldForPlural('wines')} />
                    </ReferenceInput>
                    <NumberInput label={'Original Price of Bottle'} source={'originalPriceOfBottle'}/>
                    <NumberInput label={'Original Price of Glass'} source={'originalPriceOfGlass'}/>
                    <NumberInput label={'Discount Price of Bottle'} source={'discountPriceOfBottle'}/>
                    <NumberInput label={'Discount Price of Glass'} source={'discountPriceOfGlass'}/>
                </SimpleFormIterator>
            </ArrayInput>)

            return true;
        } else if (fieldName === 'banner') {
            fieldList.push(<ArrayInput source="banner">
                <SimpleFormIterator>
                    <MyInputFile label="Banner" folder="restaurant-banner" />
                </SimpleFormIterator>
            </ArrayInput>);
            return true;
        } else if (fieldName === 'couponCode') {
            fieldList.push(<NumberInput label={'Coupon code'} source={'couponCode'} max={9999} min={0} step={1} />);
            return true;
        } else if (fieldName === 'descriptionEN' || fieldName === 'descriptionCN') {
            let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
            fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);
            fieldList.push(<TextInput label={fieldLabel} source={fieldName} fullWidth multiline />);
            return true;
        }
        return false;
    }
}

export default RestaurantCreate;
