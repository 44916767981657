import React from 'react';
import {
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    ArrayField,
    FunctionField
} from 'react-admin';
import CustomTableShow from './CustomTableShow';


class EventShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'exhibitors') {

            fieldList.push(<ReferenceArrayField label={'Distributors'} source={fieldName} reference={'distributors'}>
                <SingleFieldList>
                    <ChipField source="companyNameEN" />
                </SingleFieldList>
            </ReferenceArrayField>);
            return true;
        } else if (fieldName === 'wineCategory') {

            fieldList.push(<ReferenceArrayField source={fieldName} reference={'wine-categories'}>
                <SingleFieldList>
                    <ChipField source="nameEN" />
                </SingleFieldList>
            </ReferenceArrayField>);
            return true;
        } else if (fieldName === 'wineTags') {
            fieldList.push(<ArrayField source={fieldName}>
                <SingleFieldList>
                    <FunctionField render={(record) =>
                        <ChipField record={{ name: record }} source="name" />
                    } />
                </SingleFieldList>
            </ArrayField>);
            return true;
        }
        return false;
    }
}

export default EventShow;