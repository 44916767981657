import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    SelectInput,
    AutocompleteArrayInput,
    DateInput,
    BooleanInput,
    ReferenceArrayInput,
    ReferenceInput,
    AutocompleteInput,
    ArrayInput,
    SimpleFormIterator,
    Toolbar,
    SaveButton,
    DeleteButton
} from 'react-admin';
import { getFieldsForRessource } from '../utils/fieldsForRessource';
import dateUtils from '../utils/dateUtils';
import utils from '../utils/utils';
import dataProvider from '../utils/dataProvider';

class CustomTableEdit extends React.Component {
    state = {
        data: null,
        hideDelete: true,
     }

    CustomToolbar = props =>  {
        return (
        // <Toolbar {...props} classes={useStyles()}>
        <Toolbar {...props} >
            <SaveButton />
            {this.state.hideDelete ? null : <DeleteButton /> }

        </Toolbar>
        )
    };
    ShowTitle = ({ record }) => {
        return <span>{this.props.resource} {record.number ? `${record.number}` : ``}</span>;
        // return <span>{this.props.resource} {record.number ? `${record.number}` : `${record.id}`}</span>;
    };

    customHandled(fields, fieldName, fieldList, parentFieldName) {
        return false;
    }

    testuub(file) {
        return new Promise((resolve, reject) => {
            console.log('file');
            console.log(file);
            dataProvider.uploadFile('panorama-title', file).then((result) => {
                console.log('result');
                console.log(result);
                if (result.data) {
                    console.log('PASSED HERE');
                    console.log(result.data);
                    resolve(result.data);
                }
                resolve(4);
            }).catch((err) => {
                console.error(err);
                reject(err);
            });
        });
    }

    generateFromFields(fields, parentFieldName) {
        const that = this;

        const fieldList = [];
        for (const fieldName in fields) {
            let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
            fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);
            if (!this.customHandled(fields, fieldName, fieldList, parentFieldName)) {

                if (fields[fieldName].type === 'String' && fields[fieldName].enum !== undefined) {
                    fieldList.push(<SelectInput source={fieldName} label={fieldLabel} choices={this.transformInChoices(fields[fieldName].enum)} />);
                } else if (fields[fieldName].type === 'String') {
                    fieldList.push(<TextInput source={fieldName} label={fieldLabel}/>);
                } else if (fields[fieldName].type === 'Number') {
                    fieldList.push(<NumberInput source={fieldName} label={fieldLabel}/>);
                } else if (fields[fieldName].type === 'Date') {
                    // fieldList.push(<DateInput source={fieldName} format={dateUtils.dateFormatter} parse={dateUtils.dateParser} />)
                    fieldList.push(<DateInput source={fieldName} label={fieldLabel} parse={dateUtils.dateParser} />);
                    // fieldList.push(<DateTimeInput source={fieldName} label={fieldLabel} format={dateUtils.dateFormatter} parse={dateUtils.dateParser} />)
                } else if (fields[fieldName].type === 'Boolean') {
                    fieldList.push(<BooleanInput source={fieldName} label={fieldLabel}/>);
                } else if (fields[fieldName].type === 'ObjectId') {
                    const plural = utils.getPlural(fields[fieldName].ref);
                    fieldList.push(<ReferenceInput label={fieldLabel} source={fieldName} reference={plural} allowEmpty>
                        <AutocompleteInput optionText={utils.displayFieldForPlural(plural)} />
                    </ReferenceInput>);
                } else if (fields[fieldName].type === 'Object') {
                    // fieldLabel +=  ' (max: 20Mb)';
                    // fieldList.push(<MyInputFile source={fieldName} label={fieldLabel} />)

                    // fieldList.push(<FileInput source={fieldName} label={fieldLabel} asyncParse={this.testuub()}
                    // format={file => {
                    //     // console.log('file')
                    //     // console.log(file)
                    //     return file ? file.name: null
                    //     }
                    // }>
                    //         <FileField source="src" title="title" />
                    //     </FileInput>)
                } else if (Array.isArray(fields[fieldName].type)) {

                    if (fields[fieldName].type[0].type === 'ObjectId') {
                        const plural = utils.getPlural(fields[fieldName].type[0].ref);

                        fieldList.push(<ReferenceArrayInput label={fieldLabel} source={fieldName} reference={plural} allowEmpty>
                            <AutocompleteArrayInput optionText={utils.displayFieldForPlural(plural)} />
                        </ReferenceArrayInput>);
                    } else {

                        var subObject;
                        if (fields[fieldName].type[0].children) {
                            subObject = that.generateFromFields(fields[fieldName].type[0].children, fieldName);

                            fieldList.push(<ArrayInput source={fieldName}>
                                <SimpleFormIterator>
                                    {subObject}
                                </SimpleFormIterator>
                            </ArrayInput>);
                        } else {
                            subObject = that.generateFromFields(fields[fieldName].type, fieldName);

                            fieldList.push(<ArrayInput source={fieldName}>
                                <SimpleFormIterator>
                                    {subObject}
                                </SimpleFormIterator>
                            </ArrayInput>);
                        }

                    }
                } else if (Array.isArray(fields[fieldName].type)) {

                    console.log(fields[fieldName].type);

                    var subObject = that.generateFromFields(fields[fieldName].type[0], fieldName);

                    fieldList.push(<ArrayInput source={fieldName} label={fieldLabel}>
                        <SimpleFormIterator>
                            {subObject}
                        </SimpleFormIterator>
                    </ArrayInput>);


                    // fieldList.push(<ReferenceArrayInput label={fieldName} source={fieldName} reference={fieldName} allowEmpty>
                    //         <SelectArrayInput optionText="name" />
                    // </ReferenceArrayInput>)

                }
            }
        }
        return fieldList;
    }

    componentDidMount() {
        const that = this;

        return getFieldsForRessource(this.props.resource).then((fields) => {

            const fieldList = that.generateFromFields(fields, null);

            this.setState({
                data: <Edit {...this.props} title={<this.ShowTitle />}>
                    <SimpleForm toolbar={< that.CustomToolbar />}>
                        {fieldList}
                    </SimpleForm>
                </Edit>
            });
        });
    }

    render() {

        if (!this.state.data) {
            return <div />;
        }

        return this.state.data;
    }

    transformInChoices(base) {
        const returnObject = [];
        for (const obj in base) {
            if (base[obj]) {
                returnObject.push({
                    'id': base[obj],
                    'name': base[obj]
                });
            } else {
                returnObject.push({
                    'id': null,
                    'name': 'None'
                });
            }
        }
        return returnObject;
    }

}

export default CustomTableEdit;