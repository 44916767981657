import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    SingleFieldList,
    ArrayField,
    Datagrid
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ImageFromAPIField from '../fields/ImageFromAPIField';
import VideoFromAPIField from '../fields/VideoFromAPIField';

class RegionList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'items') {

            // fieldList.push(<ReferenceArrayField source={fieldName} reference={'distributors'}>
            //     <SingleFieldList>
            //         <ChipField source="companyNameEN" />
            //     </SingleFieldList>
            // </ReferenceArrayField>);
            return true;
        } else if (fieldName === 'regionMap') {
            fieldList.push(<ImageFromAPIField title= "map" source={fieldName} field="uri" folder="region-map" height="300" />);
            return true;
        } else if (fieldName === 'regionMapEN') {
            fieldList.push(<ImageFromAPIField label="Region map (EN)" title= "map" source={fieldName} field="uri" folder="region-map" height="300" />);
            return true;
        } else if (fieldName === 'regionMapCN') {
            fieldList.push(<ImageFromAPIField label="Region map (CN)" title= "map" source={fieldName} field="uri" folder="region-map" height="300" />);
            return true;
        }
        return false;
    }

    PostShow(props) {
        console.log(props.record);
        console.log('-------------------');
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>


                <ArrayField source="items">
                    <Datagrid linkType={false}>
                        <TextField label= "Name (EN)" source={'nameEN'}/>
                        <TextField label= "Name (CN)" source={'nameCN'}/>

                        <TextField label= "Short description (EN)" source={'shortDescriptionEN'}/>
                        <TextField label= "Short description (CN)" source={'shortDescriptionCN'}/>

                        <TextField label= "Description (EN)" source={'descriptionEN'}/>
                        <TextField label= "Description (CN)" source={'descriptionCN'}/>

                        <TextField source={'region'}/>

                        <TextField source={'xPos'}/>
                        <TextField source={'yPos'}/>
                        <TextField source={'height'}/>
                        <TextField source={'width'}/>

                        <ImageFromAPIField {...props} title= "Cover" source={'titleImageEN'} field="uri" folder="region-map-title" height="100" />
                        <ImageFromAPIField {...props} title= "Cover" source={'titleImageCN'} field="uri" folder="region-map-title" height="100" />
                        <ImageFromAPIField {...props} title= "Cover" source={'coverPicture'} field="uri" folder="region-map-cover" height="100" />
                        <VideoFromAPIField {...props} title= "Video" source={'video'} field="uri" folder="region-map-video" height="300" />

                        <ArrayField source="images">
                            <SingleFieldList>
                                <ImageFromAPIField {...props} title= "Cover" source={''} field="uri" folder="region-map-image" height="100" />
                            </SingleFieldList>
                        </ArrayField>
                    </Datagrid>
                </ArrayField>

            </SimpleShowLayout>
        </Show>;
    }
}

export default RegionList;