import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
    NumberField,
    Datagrid,
    ArrayField
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ImageFromAPIField from '../fields/ImageFromAPIField';


class EventMapList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'eventMap') {
            fieldList.push(<ImageFromAPIField source={fieldName} field="uri" folder="event-map" height="250"/>);
            return true;
        } else if (fieldName === 'eventMapEN') {
            fieldList.push(<ImageFromAPIField label="Event map (EN)" source={fieldName} field="uri" folder="event-map" height="250"/>);
            return true;
        } else if (fieldName === 'eventMapCN') {
            fieldList.push(<ImageFromAPIField label="Event map (CN)" source={fieldName} field="uri" folder="event-map" height="250"/>);
            return true;
        } else if (fieldName === 'event') {
            fieldList.push(<ReferenceField source={fieldName} reference={'events'}>
                <TextField source="nameEN" />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'items') {
            return true;
        }
        return false;
    }

    PostShow(props) {
        console.log(props.record);
        console.log('-------------------');
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>

                <ArrayField source="items">
                    <Datagrid linkType={false}>
                        <TextField label= "Name (EN)" source={'nameEN'}/>
                        <TextField label= "Name (CN)" source={'nameCN'}/>
                        <TextField label= "Description (EN)" source={'descriptionEN'}/>
                        <TextField label= "Description (CN)" source={'descriptionCN'}/>
                        <NumberField source={'xPos'}/>
                        <NumberField source={'yPos'}/>
                        <NumberField source={'height'}/>
                        <NumberField source={'width'}/>

                        <ImageFromAPIField {...props} title= "Picture" source={'picture'} field="uri" folder="event-map-picture" height="100" />


                    </Datagrid>
                </ArrayField>


            </SimpleShowLayout>
        </Show>;
    }
}

export default EventMapList;