
import decodeJwt from 'jwt-decode';

export default {


    login: ({ username, password }) => {
        const request = new Request(`${process.env.REACT_APP_API_URL}/auth/login`, {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        }).then(({ token, expireAt }) => {
            const decodedToken = decodeJwt(token);
            const expireAtTime = parseInt(Date.parse(expireAt) / 1000);
            console.log(expireAtTime);

            localStorage.setItem('token', token);
            localStorage.setItem('permissions', decodedToken.permissions);
            // localStorage.setItem('expireAt', decodedToken.exp);
            localStorage.setItem('expireAt', expireAtTime);
            localStorage.setItem('status', 'login');
        });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        localStorage.removeItem('expireAt');
        localStorage.removeItem('status');
        return Promise.resolve();
    },
    checkError: (error) => {

        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('expireAt');
            localStorage.removeItem('status');
            return Promise.reject();
        }
        return Promise.resolve();

    },
    checkAuth: () => localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),
    getPermissions: () => {

        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    }
};