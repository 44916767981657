import React from 'react';
import CustomTableEdit from './CustomTableEdit';
import MyInputFile from '../fields/MyInputFile';

class DistributorEdit extends CustomTableEdit {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'logo') {
            fieldList.push(<MyInputFile source={fieldName} label={'Logo'} folder={'distributor-logo'} />);
            return true;
        } else if (fieldName === 'qrCode') {
            fieldList.push(<MyInputFile source={fieldName} label={'QR Code'} folder={'distributor-qr-code'} />);
            return true;
        }
        return false;
    }
}

export default DistributorEdit;
