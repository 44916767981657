import React from 'react';
import {
    Show,
    SimpleShowLayout,
    SingleFieldList,
    FunctionField,
    ArrayField,
    ReferenceField,
    TextField
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ImageFromAPIField from '../fields/ImageFromAPIField';


class PhotoGalleryList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'pictures') {
            return true;
        } else if (fieldName === 'wineCategory') {
            fieldList.push(<ReferenceField source={fieldName} reference={'wine-categories'} link="show">
                <TextField source="nameEN" />
            </ReferenceField>);
            return true;
        }
        return false;
    }

    PostShow(props) {
        console.log(props.record);
        console.log('-------------------');
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>

                <ArrayField source="pictures">
                    <SingleFieldList>
                        <FunctionField render={(record) =>
                            <ImageFromAPIField {...props} record={{ ...record }} title= "Picture" field="uri" folder="gallery-picture" height="100" />
                        } />
                    </SingleFieldList>
                </ArrayField>

            </SimpleShowLayout>
        </Show>;
    }
}

export default PhotoGalleryList;
