import React from 'react';
import {
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    TextInput, ReferenceInput, AutocompleteInput
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';
import MyInputFile from '../fields/MyInputFile';
import BatchUploadField from '../fields/BatchUploadField';
import utils from '../utils/utils';

class PhotoGalleryCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {

        if (fieldName === 'pictures') {
            // fieldList.push(<ArrayInput source="pictures">
            //     <SimpleFormIterator>
            //         <MyInputFile label="Picture" folder="gallery-picture" />
            //     </SimpleFormIterator>
            // </ArrayInput>);

            fieldList.push(<BatchUploadField source="pictures" label="Picture" folder="gallery-picture"/>);

            return true;
        } else if (fieldName === 'event') {
            const plural = utils.getPlural(fields[fieldName].ref)
            fieldList.push(<ReferenceInput label="Event" source={fieldName} reference={plural} allowEmpty perPage={500}>
                <AutocompleteInput optionText={utils.displayFieldForPlural(plural)} />
            </ReferenceInput>);

            return true;
        } else if (fieldName === 'wineCategory') {
            fieldList.push(<ReferenceInput label="Wine Category" source={'wineCategory'} reference={'wine-categories'} allowEmpty perPage={500}>
                <AutocompleteInput optionText={'nameEN'} />
            </ReferenceInput>);

            return true;
        }
        return false;
    }
}

export default PhotoGalleryCreate;
