import React from 'react';
import {
    Filter,
    TextInput,
    SelectInput,
    SingleFieldList,
    Show,
    SimpleShowLayout,
    ArrayField,
    Datagrid,
    TextField,
    FunctionField,
    ReferenceField,
    NumberField,
    TopToolbar,
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ImageFromAPIField from '../fields/ImageFromAPIField';
import RestaurantAnalyticsExport from '../actions/RestaurantAnalyticsExport';

class RestaurantList extends CustomTableList {

    myFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn />

            <SelectInput label="Type" source="type" allowEmpty
                choices={[
                    { id: 'bar', name: 'Bar' },
                    { id: 'cantonese', name: 'Cantonese' },
                    { id: 'chinese', name: 'Chinese' },
                    { id: 'french', name: 'French' },
                    { id: 'fusion', name: 'Fusion' },
                    { id: 'italian', name: 'Italian' },
                    { id: 'japanese', name: 'Japanese' },
                    { id: 'jiangnan_cuisine', name: 'Jiangnan cuisine' },
                    { id: 'spanish', name: 'Spanish' },
                    { id: 'steak', name: 'Steak' },
                    { id: 'western', name: 'Western' },
                ]} />
        </Filter>
    );

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'banner' || fieldName === 'descriptionEN' || fieldName === 'descriptionCN' || fieldName === 'wines'
            || fieldName === 'addressEN' || fieldName === 'addressCN' || fieldName === 'openingEN' || fieldName === 'openingCN'
            || fieldName === 'latitude' || fieldName === 'longitude') {
            return true;
        }

        return false;
    }

    PostShow(props) {

        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>
                <ArrayField source="banner">
                    <SingleFieldList>
                        <FunctionField render={(record) =>
                            <ImageFromAPIField {...props} record={{ ...record }} title="Banner" field="uri" folder="restaurant-banner" height="100" />
                        } />
                    </SingleFieldList>
                </ArrayField>

                <TextField label="Description (EN)" source={'descriptionEN'} />
                <TextField label="Description (CN)" source={'descriptionCN'} />

                <TextField label="Opening hour (EN)" source={'openingEN'} />
                <TextField label="Opening hour (CN)" source={'openingCN'} />

                <TextField label="Address (EN)" source={'addressEN'} />
                <TextField label="Address (CN)" source={'addressCN'} />

                <TextField label="Latitude" source={'latitude'} />
                <TextField label="Longitude" source={'longitude'} />

                <ArrayField label="Wines" source={'wines'}>
                    <Datagrid>
                        <ReferenceField label="Wine" source={'wine'} reference={'wines'} allowEmpty>
                            <TextField source='nameEN' />
                        </ReferenceField>
                        <NumberField label="Original Price of Bottle" source={'originalPriceOfBottle'} />
                        <NumberField label="Original Price of Glass" source={'originalPriceOfGlass'} />
                        <NumberField label="Discount Price of Bottle" source={'discountPriceOfBottle'} />
                        <NumberField label="Discount Price of Glass" source={'discountPriceOfGlass'} />
                    </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
        </Show>;
    }

    customActions = (props) => {
        return (<TopToolbar>
            <RestaurantAnalyticsExport {...props} />
        </TopToolbar>)
    }
}

export default RestaurantList;
