import React from 'react';
import {
    TextInput,
    ReferenceArrayInput,
    AutocompleteArrayInput, ReferenceInput, AutocompleteInput
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';
import MyInputFile from '../fields/MyInputFile';
import utils from "../utils/utils";

class QuizzEdit extends CustomTableEdit {

    customHandled(fields, fieldName, fieldList, parentFieldName) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'coverEN') {
            fieldList.push(<MyInputFile source={fieldName} label={'Cover EN'} folder={'quizz-cover'} />);
            return true;
        } else if (fieldName === 'coverCN') {
            fieldList.push(<MyInputFile source={fieldName} label={'Cover CN'} folder={'quizz-cover'} />);
            return true;
        } else if (fieldName === 'image' && parentFieldName === 'questions') {
            fieldList.push(<MyInputFile source={fieldName} label={'Image'} folder={'quizz-question'} />);
            return true;
        } else if (fieldName === 'image' && parentFieldName === 'answers') {
            fieldList.push(<MyInputFile source={fieldName} label={'Image'} folder={'quizz-answer'} />);
            return true;
        } else if (fieldName === 'titleEN') {
            fieldList.push(<MyInputFile source={fieldName} label={'Title EN'} folder={'quizz-title'} />);
            return true;
        } else if (fieldName === 'titleCN') {
            fieldList.push(<MyInputFile source={fieldName} label={'Title CN'} folder={'quizz-title'} />);
            return true;
        } else if (fieldName === 'badge') {
            const plural = utils.getPlural(fields[fieldName].ref);
            fieldList.push(<ReferenceInput label={ 'Badge' } source={fieldName} reference={plural} allowEmpty>
                <AutocompleteInput optionText={utils.displayFieldForPlural(plural)} />
            </ReferenceInput>);
            return true;
        }

        return false;
    }
}

export default QuizzEdit;
