import React from 'react';
import {
    AutocompleteInput,
    ReferenceInput,
    TextInput,
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';
import MyInputFile from '../fields/MyInputFile';
import BatchUploadField from "../fields/BatchUploadField";

class CelebrityCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList, parentFieldName) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'banner') {
            fieldList.push(<MyInputFile source={fieldName} label='Banner' folder='celebrity-banner' />);
            return true;
        } else if (fieldName === 'coverPicture') {
            fieldList.push(<MyInputFile source={fieldName} label='Cover image' folder='celebrity-cover' />);
            return true;
        } else if (fieldName === 'images') {
            fieldList.push(<BatchUploadField source="images" label="Images" folder="celebrity-image"/>);
            return true;
        } else if (fieldName === 'quotationEN' || fieldName === 'quotationCN') {
            let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
            fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);
            fieldList.push(<TextInput label={fieldLabel} source={fieldName} fullWidth multiline />);
            return true;
        } else if (fieldName === 'wineCategory') {
            fieldList.push(<ReferenceInput label="Wine Category" source={'wineCategory'} reference={'wine-categories'} allowEmpty perPage={500}>
                <AutocompleteInput optionText={'nameEN'} />
            </ReferenceInput>);

            return true;
        } else if (fieldName === 'platformIcon' && parentFieldName === 'socialPlatforms') {
            fieldList.push(<MyInputFile source={fieldName} label={'Icon'} folder={'celebrity-icon'} />);
            return true;
        }
        return false;
    }
}

export default CelebrityCreate;
