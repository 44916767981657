import React from 'react';
import CustomTableList from './CustomTableList';
import ElipsizeTextField from '../fields/ElipsizeTextField';


class SurveyList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'introEN') {
            fieldList.push(<ElipsizeTextField source={fieldName} field={fieldName} maxLength={100}/>);
            return true;
        } else if (fieldName === 'introCN') {
            fieldList.push(<ElipsizeTextField source={fieldName} field={fieldName} maxLength={100}/>);
            return true;
        } else if (fieldName === 'questions') {
            return true;
        }
        return false;
    }

}

export default SurveyList;