import React from 'react';
import {
    ReferenceField,
    TextField,
    ChipField
} from 'react-admin';
import CustomTableShow from './CustomTableShow';


class SurveyAnswerShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'user') {
            fieldList.push(<ReferenceField source={fieldName} reference={'wechat-users'} link="show">
                <TextField source="openid" />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'survey') {
            fieldList.push(<ReferenceField source={fieldName} reference={'surveys'} link="show">
                <TextField source="titleEN" />
            </ReferenceField>);
            return true;
        }
        return false;
    }
}

export default SurveyAnswerShow;