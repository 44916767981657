import React from 'react';
import {
    TextField,
    ReferenceField,
    DateField,
    Filter,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import CustomTableList from './CustomTableList';
import utils from '../utils/utils';


class SurveyAnswerList extends CustomTableList {
    myFilter = (props) => (
        <Filter {...props}>
            <ReferenceInput label="Survey" source="survey" reference="surveys" alwaysOn allowEmpty >
                <AutocompleteInput optionText="titleEN" />
            </ReferenceInput>
        </Filter>
    );

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'survey') {
            fieldList.push(<DateField source="createdAt" showTime />);

            const plural = `${fields[fieldName].ref}s`;
            fieldList.push(<ReferenceField source={fieldName} reference={plural} link="show">
                <TextField source={utils.displayFieldForPlural(plural)} />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'user') {
            fieldList.push(<ReferenceField source={fieldName} reference={'wechat-users'} link="show">
                <TextField source="openid" />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'questions') {
            return true;
        }
        return false;
    }

}

export default SurveyAnswerList;
