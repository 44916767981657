import React from 'react';
import {
    DateTimeInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    SelectInput
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';
import MyInputFile from '../fields/MyInputFile';

class EventCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'exhibitors') {
            fieldList.push(<ReferenceArrayInput label={'Distributors'} source={'exhibitors'} reference={'distributors'} allowEmpty>
                <AutocompleteArrayInput optionText={'companyNameEN'} />
            </ReferenceArrayInput>);
            return true;
        } else if (fieldName === 'bannerEN') {
            fieldList.push(<MyInputFile source={fieldName} label={'Banner EN'} folder={'event-banner'} />);
            return true;
        } else if (fieldName === 'bannerCN') {
            fieldList.push(<MyInputFile source={fieldName} label={'Banner CN'} folder={'event-banner'} />);
            return true;
        } else if (fieldName === 'picture') {
            fieldList.push(<MyInputFile source={fieldName} label={'Picture'} folder={'event-picture'} />);
            return true;
        } else if (fieldName === 'startDate') {
            fieldList.push(<DateTimeInput source={fieldName} label={'Start Date'} />);
            return true;
        } else if (fieldName === 'endDate') {
            fieldList.push(<DateTimeInput source={fieldName} label={'End Date'} />);
            return true;
        } else if (fieldName === 'wineCategory') {
            fieldList.push(<ReferenceArrayInput label={'Wine Category'} source={'wineCategory'} reference={'wine-categories'} allowEmpty>
                <AutocompleteArrayInput optionText={'nameEN'} />
            </ReferenceArrayInput>);
            return true;
        } else if (fieldName === 'producers') {
            fieldList.push(<ReferenceArrayInput label={'Producers'} source={'producers'} reference={'producers'} allowEmpty perPage={500}>
                <AutocompleteArrayInput optionText={'companyNameEN'} />
            </ReferenceArrayInput>);
            return true;
        } else if (fieldName === 'wineTags') {
            fieldList.push(<ArrayInput source="wineTags">
                <SimpleFormIterator>
                    <SelectInput label={'Select wine tag'} choices={[
                        { id: '2022_selection', name: '2022_selection' },
                        { id: '2022_selection_50', name: '2022_selection_50' },
                        { id: '2022_selection_newref', name: '2022_selection_newref' },
                        { id: '2023_selection_newref', name: '2023_selection_newref' },
                    ]} />
                </SimpleFormIterator>
            </ArrayInput>);
            return true;
        } else if (fieldName === 'winesBannerEN') {
            fieldList.push(<MyInputFile source={fieldName} label={'Wines List Banner EN'} folder={'event-wines-banner'} />);
            return true;
        } else if (fieldName === 'winesBannerCN') {
            fieldList.push(<MyInputFile source={fieldName} label={'Wines List Banner CN'} folder={'event-wines-banner'} />);
            return true;
        }
        return false;
    }
}

export default EventCreate;
