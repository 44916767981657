import React from 'react';
import {
    Filter,
    TextInput,
    ReferenceArrayField,
    SelectInput,
    SingleFieldList,
    Show,
    SimpleShowLayout,
    ArrayField,
    Datagrid,
    TextField,
    NumberField,
    FunctionField,
    ChipField,
    ReferenceField,
    BooleanField
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ImageFromAPIField from '../fields/ImageFromAPIField';
import utils from '../utils/utils';

class WineList extends CustomTableList {

    myFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn />

            <SelectInput label="Category" source="category" allowEmpty
                choices={[
                    { id: 'red', name: 'Red' },
                    { id: 'white', name: 'White' },
                    { id: 'rose', name: 'Rose' },
                    { id: 'sparkling', name: 'Sparkling' },
                    { id: 'sweet', name: 'Sweet' },
                    { id: 'cremant', name: 'Cremant' },
                ]}
            />

            <SelectInput label="Tag" source="tags" allowEmpty
                choices={[
                    { id: '2022_selection', name: '2022 selection 100' },
                    { id: '2022_selection_50', name: '2022 selection 50' },
                    { id: '2022_selection_newref', name: '2022 selection newref' },
                    { id: '2023_selection_newref', name: '2023 selection newref' },
                    { id: '2024_selection_100', name: '2024 selection 100' },
                ]}
            />
        </Filter>
    );

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'picture') {
            fieldList.push(<ImageFromAPIField source={fieldName} field="uri" folder="wine-picture" height="200"/>);
            return true;
        } else if (fieldName === 'grapeType' || fieldName === 'descriptionEN' || fieldName === 'descriptionCN' || fieldName === 'distributors'
        || fieldName === 'producers' || fieldName === 'sellingPoints' || fieldName === 'environmentalPolicy' || fieldName === 'others'
        ||fieldName === 'modernLabel' || fieldName === 'pictureKol' || fieldName === 'alcoholPercent' || fieldName === 'rewardsEN'
        || fieldName === 'rewardsCN' || fieldName === 'sellingPriceRange' || fieldName === 'annualProduction' || fieldName === 'MOQ'
        || fieldName === 'celebrity' || fieldName === 'occasion' || fieldName === 'recipe' || fieldName === 'nameKol' || fieldName === 'pictureJury'
        || fieldName === 'jury' || fieldName === 'sellingChannels' || fieldName === 'appelationEN' || fieldName === 'appelationCN'
        || fieldName === 'simplyBordeaux' || fieldName === 'bottleSize' || fieldName === 'price') {
            return true;
        } else if (fieldName === 'tags') {
            fieldList.push(<ArrayField source="tags" label="List tag">
                <SingleFieldList>
                    <FunctionField render={(record) => (
                        <ChipField record={{ name: record }} source="name" />
                    )} />
                </SingleFieldList>
            </ArrayField>)

            return true;
        } else if (fieldName === 'keyCategory') {
            fieldList.push(<TextField label="Key category" source={'keyCategory'}/>);
            fieldList.push(<ReferenceArrayField source={'distributors'} reference={'distributors'}>
                <SingleFieldList>
                    <ChipField source={utils.displayFieldForPlural('distributors')} />
                </SingleFieldList>
            </ReferenceArrayField>);
            fieldList.push(<ReferenceArrayField source={'producers'} reference={'producers'}>
                <SingleFieldList>
                    <ChipField source={utils.displayFieldForPlural('producers')} />
                </SingleFieldList>
            </ReferenceArrayField>);
            return true;
        }

        return false;
    }

    PostShow(props) {
        console.log(props.record);
        console.log('-------------------');
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>

                <TextField label="Description (EN)" source={'descriptionEN'}/>
                <TextField label="Description (CN)" source={'descriptionCN'}/>

                <TextField label="Appelation (EN)" source={'appelationEN'}/>
                <TextField label="Appelation (CN)" source={'appelationCN'}/>

                <ArrayField source="grapeType">
                    <Datagrid linkType={false}>
                        <FunctionField label="Grape" render={record => {
                            if (record.grape == 'muscadet') {
                                return 'muscadelle';
                            } else {
                                return record.grape;
                            }
                        }} />
                        <TextField source={'percentage'}/>
                    </Datagrid>
                </ArrayField>

                <NumberField label="Alcohol Percent" source={'alcoholPercent'}/>
                <TextField label="Rewards (English)" source={'rewardsEN'}/>
                <TextField label="Rewards (Chinese)" source={'rewardsCN'}/>
                <TextField label="Bottle size" source={'bottleSize'}/>
                <TextField label="Retail price" source={'price'}/>
                <TextField label="Selling price range" source={'sellingPriceRange'}/>
                <NumberField label="Annual production" source={'annualProduction'}/>
                <NumberField label="MOQ" source={'MOQ'}/>

                <BooleanField label="Simply Bordeaux" source={'simplyBordeaux'} />

                <TextField label="Environmental policy" source={'environmentalPolicy'}/>
                <TextField label="Others" source={'others'}/>
                <TextField label="Modern label" source={'modernLabel'}/>

                <ReferenceArrayField source={'sellingPoints'} reference={'selling-points'}>
                    <SingleFieldList>
                        <ChipField source='nameEN' />
                    </SingleFieldList>
                </ReferenceArrayField>

                <ArrayField source="sellingChannels" label="Selling channel tag">
                    <SingleFieldList>
                        <FunctionField render={(record) => {
                            let sellingChannels ={
                                "channel_ecommerce": "E-commerce",
                                "channel_catering": "Restaurant",
                                "channel_supermarket": "Supermarket",
                            }
                            return <ChipField record={{ name: sellingChannels[record] }} source="name" />
                        }} />
                    </SingleFieldList>
                </ArrayField>

                <ReferenceField source={'celebrity'} reference={'celebrities'} link="show" label={'KOL'}>
                    <TextField source="nameEN" />
                </ReferenceField>
                <TextField label="Picture KOL"/>
                <ImageFromAPIField source={'pictureKol'} field="uri" folder="wine-picture-kol" height="200"/>

                <ReferenceField source={'jury'} reference={'juries'} link="show" label={'Jury'}>
                    <TextField source="nameEN" />
                </ReferenceField>
                <TextField label="Picture Jury"/>
                <ImageFromAPIField source={'pictureJury'} field="uri" folder="wine-picture-jury" height="200"/>

                <ReferenceField source={'occasion'} reference={'occasions'} link="show">
                    <TextField source="nameEN" />
                </ReferenceField>

                <ReferenceField source={'recipe'} reference={'recipes'} link="show">
                    <TextField source="nameEN" />
                </ReferenceField>
            </SimpleShowLayout>
        </Show>;
    }
}

export default WineList;
