import React from 'react';
import {
    FileInput
} from 'react-admin';
import {useForm} from 'react-final-form';
import dataProvider from '../utils/dataProvider';
import ImageFromAPIField from '../fields/ImageFromAPIField';

const uploadFiles = (files, folder) => new Promise((resolve, reject) => {
    const promise = [];
    const uploadedFiles = [];
    files.map((file) => {
        if (!file.uri) {
            if (file.path.search(/.jpg|.jpeg|.png|.gif/i) != -1) {
                promise.push(dataProvider.uploadFile(folder, file));
            }
        } else {
            uploadedFiles.push(file);
        }
    });

    if (promise.length > 0) {
        Promise.all(
            promise
        ).then((results) => {
            if (results.length > 0) {
                console.log('PASSED HERE');
                console.log(results);
                const tempResults = uploadedFiles;
                results.map((result) => {
                    tempResults.push(result.data);
                });
                console.log('tempResults: ', tempResults);
                resolve(tempResults);
            }
            resolve(4);
        }).catch((err) => {
            console.error(err);
            reject(err);
        });
    } else {
        resolve(uploadedFiles);
    }
});

const BatchUploadField = ({source, label, folder}) => {

    const form = useForm();

    const parse = (files) => {
        uploadFiles(files, folder).then((res) => {
            console.log('upload done:');
            console.log(res);
            console.log(form);
            console.log('source');
            console.log(source);
            form.change(source, res);
        });
        return {};
    };

    return <FileInput source={source} label={label} parse={parse} multiple={true}>
        <ImageFromAPIField
            field="uri"
            folder={folder}
            height="150"/>
    </FileInput>;
};

export default BatchUploadField;