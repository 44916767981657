import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    TopToolbar,
    FunctionField
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ImageFromAPIField from '../fields/ImageFromAPIField';
import EventsExport from '../actions/EventsExport';

const copyPath = (record) => {
    navigator.clipboard.writeText(
        `/pages/enterDraw/enterDraw?landing=true&luckyDrawEvent=${record.id}`
    )
    alert('Copied successfully')
}
class LuckyDrawEventList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'nameEN') {
            fieldList.push(<TextField label="Name (EN)" source={fieldName}/>);
            return true;
        } else if (fieldName === 'nameCN') {
            fieldList.push(<TextField label="Name (CN)" source={fieldName}/>);
            fieldList.push(
                <FunctionField
                    label="MP Page path"
                    render={(record) => (
                        <span
                            className="MuiButton-root MuiButton-textSizeSmall MuiButton-textPrimary"
                            onClick={(e) => {
                                e.stopPropagation();
                                copyPath(record);
                            }}
                        >
                            Copy
                        </span>
                    )}
                />
            );
            return true;
        } else if (fieldName === 'image') {
            fieldList.push(<ImageFromAPIField source={fieldName} field="uri" folder="lucky-draw-image" height="80"/>);
            return true;
        } else if (fieldName === 'promotionPagePath') {
            fieldList.push(<TextField label="Last page redirection" source={fieldName}/>);
            return true;
        } else if (fieldName === 'winnersCount') {
            fieldList.push(<TextField label={'Winners limit'} source={fieldName} />);
            return true;
        } else if (
            fieldName === 'descriptionEN' ||
            fieldName === 'descriptionCN' ||
            fieldName === 'giftCN' ||
            fieldName === 'resultDateEN' ||
            fieldName === 'resultDateCN'
        ) {
            return true;
        }
        return false;
    }

    customActions = (props) => {
        return (<TopToolbar>
            <EventsExport {...props}/>
        </TopToolbar>)
    }

    PostShow(props) {
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>
                <TextField label="Description EN" source={'descriptionEN'}/>
                <TextField label="Description CN" source={'descriptionCN'}/>

                <TextField label="Gift CN" source={'giftCN'}/>

                <TextField label="Result Date EN" source={'resultDateEN'}/>
                <TextField label="Result Date CN" source={'resultDateCN'}/>
            </SimpleShowLayout>
        </Show>;
    }
}

export default LuckyDrawEventList;
