import React from 'react';
import {
    required,
    NumberInput,
    TextInput
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';
import MyInputFile from '../fields/MyInputFile';

class LuckyDrawEventCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'image') {
            fieldList.push(<MyInputFile source={fieldName} label={'Gift image'} folder={'lucky-draw-image'} validate={required()} />);
            return true;
        } else if (fieldName === 'winnersCount') {
            fieldList.push(<NumberInput label={'Winners limit'} source={fieldName} key={'Winners limit'} validate={required()} />);
            return true;
        } else if (fieldName === 'resultDateEN') {
            fieldList.push(<TextInput source={fieldName} label={'EN: The winner of the lucky draw will receive a SMS message. Winner will be contacted'} fullWidth validate={required()} />);
            return true;
        } else if (fieldName === 'resultDateCN') {
            fieldList.push(<TextInput source={fieldName} label={'CN: 获奖者将在XXX收到短信通知'} fullWidth validate={required()} />);
            return true;
        } else if (fieldName === 'promotionPagePath') {
            fieldList.push(<TextInput source={fieldName} label={'Last page redirection'} fullWidth validate={required()} />);
            return true;
        }
        return false;
    }
}

export default LuckyDrawEventCreate;
