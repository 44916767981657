import React from 'react';
import {
    TextInput
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';
import MyInputFile from '../fields/MyInputFile';

class SellingPointCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'logo') {
            fieldList.push(<MyInputFile source={fieldName} label="Logo" folder="selling-point-logo" />);
            return true;
        } else if (fieldName === 'descriptionEN' || fieldName === 'descriptionCN' || fieldName === 'openingEN' || fieldName === 'openingCN') {
            let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
            fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);
            fieldList.push(<TextInput label={fieldLabel} source={fieldName} fullWidth multiline />);
            return true;
        }
        return false;
    }
}

export default SellingPointCreate;
