import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    Datagrid,
    ArrayField
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ImageFromAPIField from '../fields/ImageFromAPIField';


class QuizzList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'coverEN') {
            fieldList.push(<ImageFromAPIField source={fieldName} field="uri" folder="quizz-cover" height="150"/>);
            return true;
        } else if (fieldName === 'coverCN') {
            fieldList.push(<ImageFromAPIField source={fieldName} field="uri" folder="quizz-cover" height="150"/>);
            return true;
        } else if (fieldName === 'titleEN') {
            fieldList.push(<ImageFromAPIField source={fieldName} field="uri" folder="quizz-title" height="50"/>);
            return true;
        } else if (fieldName === 'titleCN') {
            fieldList.push(<ImageFromAPIField source={fieldName} field="uri" folder="quizz-title" height="50"/>);
            return true;
        } else if (fieldName === 'questions') {
            return true;
        }
        return false;
    }

    PostShow(props) {
        console.log(props.record);
        console.log('-------------------');
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>

                <ArrayField source="questions">
                    <Datagrid linkType={false}>
                        <TextField label= "Question (EN)" source={'questionEN'}/>
                        <ImageFromAPIField {...props} title= "Cover" source={'image'} field="uri" folder="quizz-question" height="100" />

                        <ArrayField source="answers">
                            <Datagrid linkType={false}>
                                <TextField label= "Answer (EN)" source={'answerEN'}/>
                                <ImageFromAPIField {...props} title= "Cover" source={'image'} field="uri" folder="quizz-answer" height="100" />
                            </Datagrid>
                        </ArrayField>
                    </Datagrid>
                </ArrayField>


            </SimpleShowLayout>
        </Show>;
    }
}

export default QuizzList;