import React from 'react';
import {
    ArrayInput,
    SimpleFormIterator,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    required
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';
import MyInputFile from '../fields/MyInputFile';
import BatchUploadField from "../fields/BatchUploadField";

class JuryCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList, parentFieldName) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'banner') {
            fieldList.push(<MyInputFile source={fieldName} label='Banner' folder='jury-banner' validate={required()} />);
            return true;
        } else if (fieldName === 'coverPicture') {
            fieldList.push(<MyInputFile source={fieldName} label='Cover image' folder='jury-cover' validate={required()} />);
            return true;
        } else if (fieldName === 'images') {
            fieldList.push(<BatchUploadField source="images" label="Images" folder="jury-image"/>);
            return true;
        } else if (fieldName === 'wineCategories') {
            fieldList.push(<ArrayInput source={'wineCategories'} label={'Wine Categories'} validate={required()}>
                <SimpleFormIterator>
                    <ReferenceInput label="Wine category" source={'wineCategory'} reference={'wine-categories'} allowEmpty perPage={500}>
                        <AutocompleteInput optionText={'nameEN'} />
                    </ReferenceInput>

                    <TextInput label={'Tasting note EN'} source={'tastingNoteEN'} key={'Tasting note EN'} fullWidth multiline resettable />
                    <TextInput label={'Tasting note CN'} source={'tastingNoteCN'} key={'Tasting note CN'} fullWidth multiline resettable />
                </SimpleFormIterator>
            </ArrayInput>);
            return true;
        } else if (fieldName === 'platformIcon' && parentFieldName === 'socialPlatforms') {
            fieldList.push(<MyInputFile source={fieldName} label={'Icon'} folder={'jury-icon'} />);
            return true;
        } else if (fieldName === 'biographyEN' || fieldName === 'biographyCN') {
            let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
            fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);
            fieldList.push(<TextInput label={fieldLabel} source={fieldName} key={fieldLabel} fullWidth multiline resettable />);
            return true;
        } else if (fieldName === 'nameEN' || fieldName === 'nameCN') {
            let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
            fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);
            fieldList.push(<TextInput label={fieldLabel} source={fieldName} key={fieldLabel} validate={required()} />);
            return true;
        }

        return false;
    }
}

export default JuryCreate;
