import * as React from 'react';
import { Fragment, useState } from 'react';
import {
    Button,
    Confirm,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';

const LuckyDrawReviewButton = ({ selectedIds }) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [winner, setWinner] = useState(true);
    const [updateMany, { loading }] = useUpdateMany(
        'lucky-draws/validate',
        selectedIds,
        { winner: winner },
        {
            onSuccess: () => {
                refresh();
                notify('Updated');
                unselectAll('lucky-draws');
            },
            onFailure: (error) => {
                refresh();
                notify(`Error: ${error.message}`, 'warning')
            },
        }
    );
    const handleClick = (s) => {
        setWinner(s);
        setOpen(true);
    }
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        updateMany();
        setOpen(false);
    };

    return (
        <Fragment>
            <Button label="Validate" onClick={() => handleClick(true)} />
            <Confirm
                isOpen={open}
                loading={loading}
                title="Update The Status Of Lucky draw"
                content="Are you sure you want to validate the selected items?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
}

export default LuckyDrawReviewButton;