import React from 'react';
import {
    Show,
    SimpleShowLayout,
    ArrayField,
    TextField,
    Datagrid,
} from 'react-admin';
import CustomTableList from './CustomTableList';

class WineRecipeList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'ingredients' || fieldName === 'instructions') {
            return true;
        }

        return false;
    }

    PostShow(props) {
        console.log(props.record);
        console.log('-------------------');
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>
                <ArrayField source="ingredients">
                    <Datagrid linkType={false}>
                        <TextField label="Name (EN)" source={'nameEN'}/>
                        <TextField label="Name (CN)" source={'nameCN'}/>
                    </Datagrid>
                </ArrayField>

                <ArrayField source="instructions">
                    <Datagrid linkType={false}>
                        <TextField label="Step (EN)" source={'stepEN'}/>
                        <TextField label="Step (CN)" source={'stepCN'}/>
                    </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
        </Show>;
    }
}

export default WineRecipeList;
