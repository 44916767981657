import React from 'react';
import {
    SingleFieldList,
    ArrayField,
    FunctionField,
} from 'react-admin';
import CustomTableShow from './CustomTableShow';
import ImageFromAPIField from "../fields/ImageFromAPIField";


class WineCategoryShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'images') {
            fieldList.push(<ArrayField source="images">
                <SingleFieldList>
                    <FunctionField render={(record) =>
                        <ImageFromAPIField record={{ ...record }} title="Image" field="uri" folder="wine-category-image" height="100" />
                    } />
                </SingleFieldList>
            </ArrayField>);
            return true;
        }
        return false;
    }
}

export default WineCategoryShow;
