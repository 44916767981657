import React from 'react';
import {
    ReferenceInput, AutocompleteInput
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';
import utils from "../utils/utils";

class ActivityRegistrationCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'event') {
            const plural = utils.getPlural(fields[fieldName].ref)
            fieldList.push(<ReferenceInput label="Event" source={fieldName} reference={plural} allowEmpty perPage={500}>
                <AutocompleteInput optionText={utils.displayFieldForPlural(plural)} />
            </ReferenceInput>);
            return true;
        } else if (fieldName === 'activity') {
            const plural = utils.getPlural(fields[fieldName].ref)
            fieldList.push(<ReferenceInput label="Activity" source={fieldName} reference={plural} allowEmpty perPage={500}>
                <AutocompleteInput optionText={utils.displayFieldForPlural(plural)} />
            </ReferenceInput>);
            return true;
        } else if (fieldName === 'user') {
            const plural = 'wechat-users'
            fieldList.push(<ReferenceInput label="User" source={fieldName} reference={plural} allowEmpty perPage={500}>
                <AutocompleteInput optionText={utils.displayFieldForPlural(plural)} />
            </ReferenceInput>);

            return true;
        }
        return false;
    }
}

export default ActivityRegistrationCreate;
