import React from 'react';

const ImageOrVideoField = ({ record, field, source, folder, height}) => {


    console.log('record');
    console.log(record);

    console.log('field');
    console.log(field);

    console.log('source');
    console.log(source);



    let obj = record[source];
    if (source.split('[').length > 1) {
        console.log('parseInt(source.split(\'[\')[1])');
        console.log(parseInt(source.split('[')[1]));
        console.log('source.split(\'.\')[1]');
        console.log(source.split('.')[1]);
        obj = record.items[parseInt(source.split('[')[1])][source.split('.')[1]];
    }
    // for (var i = 0, source = source.split('.'), len = source.length; i < len; i++) {
    //     obj = obj[source[i]];
    // }

    console.log('obj');
    console.log(obj);
    if (obj) {
        const str = obj[field];

        return (
            <div>
                <video controls width={300}>
                    <source src={`${process.env.REACT_APP_API_URL}/storage/${folder}/${str}`} type="video/webm"/>
                    <source src={`${process.env.REACT_APP_API_URL}/storage/${folder}/${str}`} type="video/mp4"/>
          Sorry, your browser doesn't support embedded videos.
                </video>
            </div>
        );
    }
    return <a href={record[field]} >{record.title}</a>;

};

export default ImageOrVideoField;