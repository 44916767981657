import React from 'react';
import {
    DateTimeInput,
    ReferenceArrayInput,
    AutocompleteArrayInput
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';
import MyInputFile from '../fields/MyInputFile';

class QuizzCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList, parentFieldName) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'coverEN') {
            fieldList.push(<MyInputFile source={fieldName} label={'Cover EN'} folder={'quizz-cover'} />);
            return true;
        } else if (fieldName === 'coverCN') {
            fieldList.push(<MyInputFile source={fieldName} label={'Cover CN'} folder={'quizz-cover'} />);
            return true;
        } else if (fieldName === 'image' && parentFieldName === 'questions') {
            fieldList.push(<MyInputFile source={fieldName} label={'Image'} folder={'quizz-question'} />);
            return true;
        } else if (fieldName === 'image' && parentFieldName === 'answers') {
            fieldList.push(<MyInputFile source={fieldName} label={'Image'} folder={'quizz-answer'} />);
            return true;
        } else if (fieldName === 'titleEN') {
            fieldList.push(<MyInputFile source={fieldName} label={'Title EN'} folder={'quizz-title'} />);
            return true;
        } else if (fieldName === 'titleCN') {
            fieldList.push(<MyInputFile source={fieldName} label={'Title CN'} folder={'quizz-title'} />);
            return true;
        } else if (fieldName === 'badge') {
            fieldList.push(<ReferenceArrayInput label={'Badge'} source={'badge'} reference={'badges'} allowEmpty>
                <AutocompleteArrayInput optionText={'nameEN'} />
            </ReferenceArrayInput>);
            return true;
        }

        return false;
    }
}

export default QuizzCreate;
