import React from 'react';
import {
    DateTimeInput,
    ReferenceArrayInput,
    AutocompleteArrayInput
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';
import MyInputFile from '../fields/MyInputFile';

class BadgeCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'image') {
            fieldList.push(<MyInputFile source={fieldName} label={'Image'} folder={'badge-image'} />);
            return true;
        } else if (fieldName === 'imageLocked') {
            fieldList.push(<MyInputFile source={fieldName} label={'Image Locked'} folder={'badge-image-locked'} />);
            return true;
        }

        return false;
    }
}

export default BadgeCreate;
