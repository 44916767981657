import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    validate: { backgroundColor: '#00FF0055' },
    refuse: { backgroundColor: '#FF000055' }
});

class ApproveLuckyDrawButton extends Component {

    handleClick = (e) => {
        const { push, record, showNotification, winner } = this.props;
        console.log('record', record)
        console.log('winner', winner)

        e.stopPropagation();
        const token = localStorage.getItem('token');

        const headers = new Headers({
            Accept: 'application/json',
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json'
        });

        fetch(`${process.env.REACT_APP_API_URL}/lucky-draws/${record.id}/validate`, {
            method: 'POST',
            headers,
            body: JSON.stringify({ winner: winner })
        }).then(response => response.json()).then((response) => {
            if (response.code === 500) {
                if (response.message) {
                    showNotification(`Error: ${response.message}`, 'warning')
                } else {
                    showNotification('Error: Lucky draw could not be updated', 'warning')
                }
            } else {
                if (this.props.winner === true) {
                    showNotification('This participant is now a winner.');
                }
            }

            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }).catch((e) => {
            console.error(e);
            showNotification('Error: Lucky draw could not be updated', 'warning')
        });
    }

    render() {

        console.log(this.props.record);
        if (this.props.record?.winner !== true) {
            return <Button className='match-validate' label="Approve" onClick={this.handleClick} >✅</Button>;
        } else {
            return <span></span>
        }

    }
}

ApproveLuckyDrawButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification,
    push,
})(ApproveLuckyDrawButton);
