import React from 'react';
import {
    AutocompleteInput,
    Filter,
    ReferenceField, ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
    TopToolbar,
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ApproveActivityRegistrationButton from '../fields/ApproveActivityRegistrationButton';
import ActivityRegistrationStatusField from '../fields/ActivityRegistrationStatusField';
import ActivityRegistrationsExport from '../actions/ActivityRegistrationsExport';
import ActivityRegistrationReviewButton from '../components/ActivityRegistrationReviewButton';

class ActivityRegistrationList extends CustomTableList {
    
    bulkActionButtons = true

    bulkAction = (props) => {
        return <ActivityRegistrationReviewButton selectedIds={props.selectedIds} />
    }

    myFilter = (props) => (
        <Filter {...props}>
            <ReferenceInput label="Event" source="event" reference="events" alwaysOn allowEmpty perPage={500}>
                <AutocompleteInput optionText="nameEN" />
            </ReferenceInput>

            <ReferenceInput label="Activity" source="activity" reference="activities" alwaysOn allowEmpty perPage={500}>
                <AutocompleteInput optionText="themeEN" />
            </ReferenceInput>

            <TextInput label="Name" source="eventRegistrationName" alwaysOn />

            <SelectInput label="Status" source="status" allowEmpty
                 choices={[
                     { id: 'pending', name: 'Pending' },
                     { id: 'active', name: 'Validated' },
                     { id: 'refused', name: 'Refused' },
                     { id: 'cancelled', name: 'Cancelled' },
                     { id: 'participated', name: 'Participated' },
                 ]}/>
        </Filter>
    );

    customHandled(fields, fieldName, fieldList) {
        const role = localStorage.getItem('permissions');

        if (fieldName === 'registerSuccessNotification' || fieldName === 'activityStartNotification' || fieldName === 'activityEndNotification') {
            return true;
        } else if (fieldName === 'user') {
            if (role === 'event-manager') {
                return true;
            }

            fieldList.push(<ReferenceField source={fieldName} reference={'wechat-users'} link="show">
                <TextField source="openid" />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'eventRegistration') {
            fieldList.push(<ReferenceField label={'Name'} source={fieldName} reference={'event-registrations'} link="show">
                <TextField source="name" />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'activity') {
            // fieldList.push(<ReferenceField source={'activity.event'} label={'Event'} reference={'events'}>
            //     <TextField source="nameEN" />
            // </ReferenceField>);
            // fieldList.push(<ReferenceField source={'activity.id'} label={'Activity'} reference={'activities'}>
            //     <TextField source="themeEN" />
            // </ReferenceField>);

            fieldList.push(<ReferenceField source={fieldName} reference={'activities'} link="show">
                <TextField source="themeEN" />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'status') {
            fieldList.push(<ActivityRegistrationStatusField label={'Status'} source={'status'} sortable={false} />);
            fieldList.push(<ApproveActivityRegistrationButton label={'Validate'} status={'active'} />);
            fieldList.push(<ApproveActivityRegistrationButton label={'Refuse'} status={'refused'} />);
            return true;
        }
        return false;
    }

    // PostShow(props) {
    //     console.log(props.record);
    //     console.log('-------------------');
    //     return <Show
    //         {...props}
    //         /* disable the app title change when shown */
    //         title=' ' >
    //         <SimpleShowLayout {...props}>
    //             <BooleanField label={'Register success notification'} source={'registerSuccessNotification'}/>
    //             <BooleanField label={'Activity start notification'} source={'activityStartNotification'}/>
    //             <BooleanField label={'Activity end notification'} source={'activityEndNotification'}/>
    //         </SimpleShowLayout>
    //     </Show>;
    // }

    customActions = (props) => {
        return (<TopToolbar>
            <ActivityRegistrationsExport {...props}/>
        </TopToolbar>)
    }
}

export default ActivityRegistrationList;
