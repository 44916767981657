import React from 'react';
import {
    TextField,
    DateField
} from 'react-admin';
import CustomTableShow from './CustomTableShow';

class ActivityShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'startDate') {
            fieldList.push(<DateField source={fieldName} showTime={true} />);
            return true;
        } else if (fieldName === 'endDate') {
            fieldList.push(<DateField source={fieldName} showTime={true} />);
            return true;
        } else if (fieldName === 'stock') {
            fieldList.push(<TextField label={'Max number of attendees per activity'} source={fieldName} />);
            return true;
        }
        return false;
    }
}

export default ActivityShow;
