import React from 'react';
import {
    SingleFieldList,
    ArrayField,
    FunctionField,
    ReferenceField,
    TextField,
} from 'react-admin';
import CustomTableShow from './CustomTableShow';
import ImageFromAPIField from "../fields/ImageFromAPIField";

class OccasionShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList, parentFieldName) {
        if (fieldName === 'images') {
            fieldList.push(<ArrayField source="images">
                <SingleFieldList>
                    <FunctionField render={(record) =>
                        <ImageFromAPIField record={{ ...record }} title="Image" field="uri" folder="occasion-image" height="100" />
                    } />
                </SingleFieldList>
            </ArrayField>);
            return true;
        } else if (fieldName === 'wineCategory') {
            fieldList.push(<ReferenceField source={fieldName} reference={'wine-categories'} link="show">
                <TextField source="nameEN" />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'image' && parentFieldName === 'foodPairings') {
            fieldList.push(<ImageFromAPIField title="Image" source={'image'} field="uri" folder="occasion-food" height="100" />);
            return true;
        }
        return false;
    }
}

export default OccasionShow;
