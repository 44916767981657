import React from 'react';
import {
    ReferenceArrayInput,
    AutocompleteArrayInput, DateTimeInput
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';
import MyInputFile from '../fields/MyInputFile';

class BadgeEdit extends CustomTableEdit {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'image') {
            fieldList.push(<MyInputFile source={fieldName} label={'Image'} folder={'badge-image'} />);
            return true;
        } else if (fieldName === 'imageLocked') {
            fieldList.push(<MyInputFile source={fieldName} label={'Image Locked'} folder={'badge-image-locked'} />);
            return true;
        }

        return false;
    }
}

export default BadgeEdit;
