import React from 'react';
import {
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    SimpleShowLayout,
    DateField,
    TextField,
    Show
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ImageFromAPIField from '../fields/ImageFromAPIField';


class BadgeList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'nameEN') {
            fieldList.push(<TextField label="Name (EN)" source={fieldName}/>);
            return true;
        } else if (fieldName === 'nameCN') {
            fieldList.push(<TextField label="Name (CN)" source={fieldName}/>);
            return true;
        } else if (fieldName === 'image') {
            fieldList.push(<ImageFromAPIField source={fieldName} field="uri" folder="badge-image" height="150"/>);
            return true;
        } else if (fieldName === 'imageLocked') {
            fieldList.push(<ImageFromAPIField source={fieldName} field="uri" folder="badge-image-locked" height="150"/>);
            return true;
        }
        return false;
    }

}

export default BadgeList;