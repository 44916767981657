import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    NumberField,
    DateField,
    BooleanField,
    ReferenceField,
    ArrayField,
    Datagrid,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
} from 'react-admin';
import { getFieldsForRessource } from '../utils/fieldsForRessource';
import dateUtils from '../utils/dateUtils';
import utils from '../utils/utils';

class CustomTableShow extends React.Component {
    state = { data: null }

    ShowTitle = ({ record }) => {
        return <span>{this.props.resource} {record.number ? `${record.number}` : ``}</span>;
        // return <span>{this.props.resource} {record.number ? `${record.number}` : `${record.id}`}</span>;
    };

    lastCheckBeforeValidate(data) {
        return new Promise((resolve, reject) => {

            if (this.customActions()) {
                resolve({
                    data: <Show {...this.props} title={<this.ShowTitle/>} actions={<this.customActions />}>
                        <SimpleShowLayout>
                            {data}
                        </SimpleShowLayout>
                    </Show>
                });
            } else {
                resolve({
                    data: <Show {...this.props} title={<this.ShowTitle/>}>
                        <SimpleShowLayout>
                            {data}
                        </SimpleShowLayout>
                    </Show>
                });
            }
        });
    }

    customHandled(fields, fieldName, fieldList, parentFieldName) {
        return false
    }


    generateFromFields(fields, parentFieldName) {
        let that = this

        var fieldList = []
        console.log('PASSED HERE')
        console.log(fields)
        for (const fieldName in fields) {
            let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
            fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);

            if (!this.customHandled(fields, fieldName, fieldList, parentFieldName)) {
                if (fields[fieldName].type === 'String') {
                    fieldList.push(<TextField source={fieldName}/>)
                } else if (fields[fieldName].type === 'Number') {
                    fieldList.push(<NumberField source={fieldName}/>)
                } else if (fields[fieldName].type === 'Date') {
                    fieldList.push(<DateField source={fieldName} format={dateUtils.dateFormatter} parse={dateUtils.dateParser} />)
                } else if (fields[fieldName].type === 'Boolean') {
                    fieldList.push(<BooleanField source={fieldName} />)
                } else if (fields[fieldName].type === 'ObjectId') {
                    const plural = utils.getPlural(fields[fieldName].ref);
                    fieldList.push(<ReferenceField source={fieldName} reference={plural} link="show">
                        <TextField source={utils.displayFieldForPlural(plural)} />
                    </ReferenceField>)
                } else if (Array.isArray(fields[fieldName].type)) {

                    console.log(fields[fieldName].type)

                    if (fields[fieldName].type[0].type === "ObjectId") {
                        const plural = utils.getPlural(fields[fieldName].type[0].ref)

                        fieldList.push(<ReferenceArrayField label={fieldLabel} source={fieldName} reference={plural} allowEmpty>
                            <SingleFieldList>
                                <ChipField source={utils.displayFieldForPlural(plural)} />
                            </SingleFieldList>
                        </ReferenceArrayField>)
                    } else {

                        var subObject
                        if ((fields[fieldName].type[0].children)) {
                            subObject = that.generateFromFields(fields[fieldName].type[0].children, fieldName)

                            fieldList.push(<ArrayField source={fieldName}>
                                <Datagrid>
                                    {subObject}
                                </Datagrid>
                            </ArrayField>)
                        } else {
                            subObject = that.generateFromFields(fields[fieldName].type, fieldName)

                            fieldList.push(<ArrayField source={fieldName}>
                                <Datagrid>
                                    {subObject}
                                </Datagrid>
                            </ArrayField>)
                        }

                    }
                }
            }
        };
        return fieldList
    }

    componentDidMount() {
        return getFieldsForRessource(this.props.resource).then((fields) => {
            let that = this

            var fieldList = that.generateFromFields(fields, null)

            this.lastCheckBeforeValidate(fieldList).then((data) => {
                this.setState(data);
            })
        });
    }

    render() {
        if (!this.state.data) {
          return <div />
        }

        return this.state.data;
    }

    customActions(props) {
        return null
    }
}

export default CustomTableShow;
