import React from 'react';

const ElipsizeTextField = ({ record, field, maxLength }) => {

    if (!maxLength) {
        maxLength = 40;
    }
    const str = record[field];
    return str ?
        <span>{str.length > maxLength ? `${str.slice(0, maxLength)}...` : str}</span>
        : null;
};

export default ElipsizeTextField;