import React from 'react';
import {
    TextInput, SelectInput
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';

class WechatUserEdit extends CustomTableEdit {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'openid') {
            fieldList.push(<TextInput source={fieldName} label='Openid' disabled />);
            return true;
        } else if (fieldName === 'unionid') {
            fieldList.push(<TextInput source={fieldName} label='Unionid' disabled />);
            return true;
        } else if (fieldName === 'role') {
            fieldList.push(<TextInput source={fieldName} label='Role' disabled />);
            return true;
        } else if (fieldName === 'type') {
            fieldList.push(<SelectInput source={fieldName} label={'User type'} choices={[
                { id: 'b2b', name: 'B2B' },
                { id: 'b2c', name: 'B2C' },
            ]} />);
            return true;
        }

        return false;
    }
}

export default WechatUserEdit;
