import React from 'react';
import {
    SingleFieldList,
    ArrayField,
    FunctionField,
    ReferenceField,
    TextField,
    Datagrid,
} from 'react-admin';
import CustomTableShow from './CustomTableShow';
import ImageFromAPIField from "../fields/ImageFromAPIField";

class CelebrityShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'images') {
            fieldList.push(<ArrayField source="images">
                <SingleFieldList>
                    <FunctionField render={(record) =>
                        <ImageFromAPIField record={{ ...record }} title="Image" field="uri" folder="celebrity-image" height="100" />
                    } />
                </SingleFieldList>
            </ArrayField>);
            return true;
        } else if (fieldName === 'wineCategory') {
            fieldList.push(<ReferenceField source={fieldName} reference={'wine-categories'} link="show">
                <TextField source="nameEN" />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'socialPlatforms') {
            fieldList.push(<ArrayField source="socialPlatforms">
                <Datagrid linkType={false}>
                    <ImageFromAPIField title="Icon" source={'platformIcon'} field="uri" folder="celebrity-icon" height="100" />
                    <TextField label="Platform (EN)" source={'platformNameEN'}/>
                    <TextField label="Platform (CN)" source={'platformNameCN'}/>
                    <TextField label="Name" source={'name'}/>
                </Datagrid>
            </ArrayField>);
            return true;
        }
        return false;
    }
}

export default CelebrityShow;
