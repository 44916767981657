import React from 'react';
import CustomTableList from './CustomTableList';
import ImageFromAPIField from '../fields/ImageFromAPIField';


class PanoramaList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'frontImage' || fieldName === 'backImage' || fieldName === 'upImage' || fieldName === 'downImage'
        || fieldName === 'rightImage' || fieldName === 'leftImage') {

            fieldList.push(<ImageFromAPIField source={fieldName} field="uri" folder="panorama-panel" height="300" />);
            return true;
        } else if (fieldName === 'grapeType') {
            return true;
        }

        return false;
    }
}

export default PanoramaList;