import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    validate: { backgroundColor: '#00FF0055' },
    refuse: { backgroundColor: '#FF000055' }
});

class ApproveActivityRegistrationButton extends Component {

    handleClick = (e) => {
        const { push, record, showNotification, status } = this.props;
        console.log(status)
        console.log(status)
        console.log(status)
        console.log(status)
        console.log(status)
        console.log(status)
        console.log(status)
        console.log(status)
        console.log(status)

        e.stopPropagation();
        const token = localStorage.getItem('token');

        const headers = new Headers({
            Accept: 'application/json',
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json'
        });

        fetch(`${process.env.REACT_APP_API_URL}/activity-registrations/${record.id}/validate`, { method: 'POST', headers, body: JSON.stringify({ status: status }) }).then(response => response.json()).then((response) => {
            if (response.code === 500) {
                if (response.message) {
                    showNotification(`Error: ${response.message}`, 'warning')
                } else {
                    showNotification('Error: Registration could not be updated', 'warning')
                }
            } else {
                if (this.props.status === 'active') {
                    showNotification('Registration approved');
                } else {
                    showNotification('Registration refused');
                }
            }
            window.location.reload();
        }).catch((e) => {
            console.error(e);
            showNotification('Error: Registration could not be updated', 'warning')
        });
    }

    render() {

        console.log(this.props.record.status);
        if (this.props.record.status === 'pending') {
            if (this.props.status === 'active') {
                return <Button className='match-validate' label="Approve" onClick={this.handleClick} >✅</Button>;
            } else {
                return <Button className='match-refuse' label="Refuse" onClick={this.handleClick} >❌</Button>;
            }
        } else {
            return <span></span>
        }

    }
}

ApproveActivityRegistrationButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification,
    push,
})(ApproveActivityRegistrationButton);
