import React from 'react';
import CustomTableCreate from './CustomTableCreate';
import MyInputFile from '../fields/MyInputFile';

class WineRecipeCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList, parentFieldName) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'banner') {
            fieldList.push(<MyInputFile source={fieldName} label='Banner' folder='wine-recipe-banner' />);
            return true;
        }
        return false;
    }
}

export default WineRecipeCreate;
