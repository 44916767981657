import React from 'react';
import {
    SingleFieldList,
    Show,
    SimpleShowLayout,
    ArrayField,
    TextField,
    FunctionField,
    ReferenceField,
    Datagrid,
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ImageFromAPIField from '../fields/ImageFromAPIField';

class CelebrityList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'descriptionEN' || fieldName === 'descriptionCN' || fieldName === 'images' || fieldName === 'socialPlatforms') {
            return true;
        } else if (fieldName === 'wineCategory') {
            fieldList.push(<ReferenceField source={fieldName} reference={'wine-categories'} link="show">
                <TextField source="nameEN" />
            </ReferenceField>);
            return true;
        }

        return false;
    }

    PostShow(props) {
        console.log(props.record);
        console.log('-------------------');
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>

                <TextField label="Description (EN)" source={'descriptionEN'} />
                <TextField label="Description (CN)" source={'descriptionCN'} />

                <TextField label="Cover image" />
                <ImageFromAPIField source="coverPicture" field="uri" folder="celebrity-cover" height="100"/>

                <TextField label="Banner" />
                <ImageFromAPIField source="banner" field="uri" folder="celebrity-banner" height="100"/>

                <ArrayField source="images">
                    <SingleFieldList>
                        <FunctionField render={(record) =>
                            <ImageFromAPIField {...props} record={{ ...record }} title="Image" field="uri" folder="celebrity-image" height="100" />
                        } />
                    </SingleFieldList>
                </ArrayField>

                <ArrayField source="socialPlatforms">
                    <Datagrid linkType={false}>
                        <ImageFromAPIField title="Icon" source={'platformIcon'} field="uri" folder="celebrity-icon" height="100" />
                        <TextField label="Platform (EN)" source={'platformNameEN'}/>
                        <TextField label="Platform (CN)" source={'platformNameCN'}/>
                        <TextField label="Name" source={'name'}/>
                    </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
        </Show>;
    }
}

export default CelebrityList;
