import React from 'react';
import {
    ArrayField,
    Datagrid,
    ReferenceField,
    TextField,
    NumberField,
    SingleFieldList,
    FunctionField,
    TopToolbar,
    EditButton
} from 'react-admin';
import CustomTableShow from './CustomTableShow';
import ImageFromAPIField from '../fields/ImageFromAPIField';

import RestaurantAnalyticsExport from '../actions/RestaurantAnalyticsExport';

class RestaurantShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'wines') {
            fieldList.push(<ArrayField label={'Wines'} source={'wines'}>
                <Datagrid>
                    <ReferenceField label={'Wine'} source={'wine'} reference={'wines'} allowEmpty>
                        <TextField source={'nameEN'}/>
                    </ReferenceField>
                    <NumberField label={'Original Price of Bottle'} source={'originalPriceOfBottle'}/>
                    <NumberField label={'Original Price of Glass'} source={'originalPriceOfGlass'}/>
                    <NumberField label={'Discount Price of Bottle'} source={'discountPriceOfBottle'}/>
                    <NumberField label={'Discount Price of Glass'} source={'discountPriceOfGlass'}/>
                </Datagrid>
            </ArrayField>);

            return true;
        } else if (fieldName === 'banner') {
            fieldList.push(<ArrayField source="banner">
                <SingleFieldList>
                    <FunctionField render={(record) =>
                        <ImageFromAPIField record={{ ...record }} title="Banner" field="uri" folder="restaurant-banner" height="100" />
                    } />
                </SingleFieldList>
            </ArrayField>);
            return true;
        }

        return false;
    }

    customActions = (props) => {
        return (<TopToolbar>
            <EditButton resource={'restaurants'} record={{ id: props?.data?.id }}/>
            <RestaurantAnalyticsExport {...props}/>
        </TopToolbar>)
    }
}

export default RestaurantShow;
